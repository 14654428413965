import { PasswordValidator } from "#Components/FormValidators";
import { submitVerifyPassword } from "#Graphql/mutate";
import { checkEmailExists } from "#Graphql/query";
import { emailRegex, phoneRegex } from "#hoc/util";

export const validateUpdateUserDetails = async (values, { initialValues }) => {
  const errors = {};
  const requiredFields = ["firstName", "lastName", "email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  if (values.email) {
    if (!emailRegex.test(values.email)) {
      errors.email = "Invalid email address";
    } else {
      if (values["email"] !== initialValues["email"]) {
        const { checkEmail = {} } = await checkEmailExists(values["email"]);
        const { userData } = checkEmail;
        if (userData) {
          errors.email = "This email already exists";
        }
      }
    }
  }
  if (values.phone && !phoneRegex.test(values.phone)) {
    errors.phone = "Invalid phone number";
  }
  return errors;
};

export const validateResetPassword = async (
  values,
  { initialValues, prevValues },
) => {
  console.log(values);
  const errors = PasswordValidator(["newPassword", "confirmPassword"])(values);
  if (values.password && values.password !== prevValues.password) {
    try {
      const { verifyPassword } = await submitVerifyPassword(values);
      if (verifyPassword && verifyPassword.code !== 200) {
        errors.password = verifyPassword.message;
      }
    } catch (err) {
      errors.password = err.message;
    }
  }
  return errors;
};
