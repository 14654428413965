import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";

export const Collapsable = (props) => {
  const {
    label,
    children,
    red,
    small,
    className = "mt-2 mb-3",
    onCollapse = () => {},
    onExpand = () => {},
    disabled,
  } = props;
  const [collapsed, setCollapsed] = useState(props.collapsed);

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);

  useEffect(() => {
    collapsed ? onCollapse() : onExpand();
  }, [collapsed]);

  const toggleCollapsed = (e) => {
    e.preventDefault();
    e.stopPropagation();
    !disabled && setCollapsed(!collapsed);
  };

  const cls = ["collapse-toggler", "d-flex", "mb-2", "align-items-center"];
  !small && cls.push("justify-content-between");
  red && cls.push("red");
  const labelCls = ["font-weight-bold", "me-2"];
  small && labelCls.push("small");
  red && labelCls.push("red");
  const iconCls = ["fa", "font-14"];
  !collapsed && !small && iconCls.push("fa-minus-circle");
  !collapsed && small && iconCls.push("fa-arrow-circle-down");
  collapsed && !small && iconCls.push("fa-plus-circle");
  collapsed && small && iconCls.push("fa-arrow-circle-right");
  return (
    <div className={className}>
      <div className={cls.join(" ")} onClick={toggleCollapsed}>
        <label className={labelCls.join(" ")}>{label}</label>
        <span>
          <i
            key={iconCls.join("-")}
            className={iconCls.join(" ")}
            title={collapsed ? "More" : "Less"}
          />
        </span>
      </div>
      <Collapse isOpen={!collapsed}>{children}</Collapse>
    </div>
  );
};

export default Collapsable;
