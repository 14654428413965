import Collapsable from "#Components/Collapsable";
import TabNav from "#Components/TabNav.jsx";
import { camelToTitle } from "#hoc/util.jsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Alert, Col, Label, Row, Table } from "reactstrap";

const CDN_SERVER = `https://${window.VITE_CDN_HOSTNAME || "cdn.clouve.com"}`;

export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const renderAppResources = ({ appId, capacity, resources }) => {
  if (resources && Object.keys(resources).length) {
    const capacityResources = Object.keys(resources)
      .filter(
        (key) =>
          key.match(new RegExp(appId, "gi")) && _.isPlainObject(resources[key]),
      )
      .map((key, idx) => {
        const { requests, limits } = resources[key] || {};
        const resourcesObj = requests || resources[key] || {};
        const { cpu, memory, storage } = resourcesObj;
        return (
          <tbody key={`${key}_${appId}_${capacity}_${idx}`}>
            <tr>
              {key ? (
                <th>
                  <Label>{key}</Label>
                </th>
              ) : null}
              <td className="text-center">{limits?.cpu || cpu}</td>
              <td className="text-center">{limits?.memory || memory}</td>
              <td className="text-center">{limits?.storage || storage}</td>
            </tr>
          </tbody>
        );
      });

    return (
      <Table size="sm" bordered>
        <thead>
          <tr>
            <th></th>
            <th className="text-center">CPU</th>
            <th className="text-center">Memory</th>
            <th className="text-center">Storage</th>
          </tr>
        </thead>
        {...capacityResources}
      </Table>
    );
  }
};

const AppDescription = ({
  title,
  description,
  iconPath,
  classNames,
  onClick = () => {},
  extraDescription,
  children,
}) => {
  return (
    <Row className={classNames.join(" ")} onClick={onClick}>
      {iconPath ? (
        <Col sm="auto" className="text-center">
          <img src={iconPath} alt={title} className="service-icon" />
        </Col>
      ) : null}
      <Col>
        <label>{title}</label>
        <p>{description}</p>
        {extraDescription}
      </Col>
      {children}
    </Row>
  );
};
const AppResources = ({
  appId,
  capacityMap = {},
  capacity,
  currentCapacity,
  resources,
  onCapacitySelect = () => {},
  disabled,
}) => {
  const capacityResources = [];
  if (resources) {
    if (resources && Object.keys(resources).length) {
      return (
        <div className="ms-1">
          {renderAppResources({ appId, capacity, resources })}
        </div>
      );
    }
  } else {
    for (let capacity in capacityMap) {
      const resources = capacityMap[capacity];
      if (resources && Object.keys(resources).length) {
        capacityResources.push({
          tabId: `/${capacity}`,
          tabLabel: camelToTitle(capacity),
          tabIcon:
            currentCapacity === capacity ? "ms-1 blue fa fa-circle-check" : "",
          tabIconAfterLabel: true,
          tabComponent: renderAppResources({ appId, capacity, resources }),
        });
      }
    }
  }
  return (
    <div className="ms-1">
      <TabNav
        defaultTab={`/${capacity}`}
        activeTab={`/${capacity}`}
        onTabChange={(tabId = "") => {
          tabId && onCapacitySelect(tabId.replace(/^\//gi, ""));
        }}
        tabs={capacityResources}
        showSingleTab={true}
        justifiedTabs={false}
        disabled={disabled}
      />
    </div>
  );
};

export const AppItem = (props) => {
  const {
    URL = "",
    extraURL = "",
    iconPath = "",
    title = "",
    description = "",
    resourcesLabel = "Capacity",
    capacityMap,
    capacity,
    currentCapacity,
    onCapacitySelect = (capacity) => {},
    resources,
    disabled,
    appId,
  } = props;
  const [collapsed, setCollapsed] = useState(props.collapsed);
  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);
  const onClick = (evt) => {
    if (!evt?.target?.tagName?.match(/^a$/gi)) {
      if (extraURL || URL) {
        window.open(extraURL || URL, "_blank").focus();
      } else {
        setCollapsed(!collapsed);
      }
    }
  };

  return (
    <AppDescription
      title={title}
      description={description}
      iconPath={iconPath}
      classNames={["service-item"]}
      onClick={onClick}
      extraDescription={
        extraURL ? (
          <p className="mt-2">
            <a href={extraURL} target="_blank">
              {extraURL}
            </a>
          </p>
        ) : URL ? (
          <p className="mt-2">
            <a href={URL} target="_blank">
              {URL}
            </a>
          </p>
        ) : null
      }
    >
      <Collapsable label={resourcesLabel} collapsed={collapsed} small={true}>
        <AppResources
          capacityMap={capacityMap}
          capacity={capacity}
          currentCapacity={currentCapacity}
          onCapacitySelect={onCapacitySelect}
          resources={resources}
          disabled={disabled}
          appId={appId}
        />
      </Collapsable>
    </AppDescription>
  );
};

export const SolutionApps = ({
  solutionItem,
  ticketDetails,
  deploymentStatus,
  selectedCapacity,
  onCapacitySelect = (capacity) => {},
  label = "Bundled Apps",
  resourcesLabel = "Capacity",
  collapsed,
  disabled,
}) => {
  const renderedApps = [];
  const currentCapacity = _.get(
    ticketDetails,
    "jsonSchema.formData.cluster.capacity",
  );
  if (solutionItem) {
    const { serviceName, serviceAttributes = {} } = solutionItem;
    const {
      suiteApps,
      suiteTitles = {},
      suiteVersions = {},
      suiteIcons = {},
      suiteDescriptions = {},
      serviceCapacity,
      serviceCapacityMap = {},
    } = JSON.parse(JSON.stringify(serviceAttributes));

    if (serviceCapacity) {
      serviceCapacityMap["x-small"] = serviceCapacity;
    }

    if (suiteApps) {
      for (const key in suiteApps) {
        let title = `${suiteTitles[key] || ""} ${suiteVersions[key] || ""}`;
        let icon = suiteIcons[key] || "";
        let description = suiteDescriptions[key] || "";
        const iconPath = icon
          ? `${CDN_SERVER}/self-service/${serviceName}/.service-config/${icon}`
          : null;
        renderedApps.push(
          <AppItem
            key={suiteApps[key]}
            iconPath={iconPath}
            title={title}
            description={description}
            capacityMap={serviceCapacityMap}
            capacity={selectedCapacity}
            onCapacitySelect={onCapacitySelect}
            disabled={disabled}
            appId={suiteApps[key]}
            currentCapacity={currentCapacity}
          />,
        );
      }
    } else if (Object.keys(serviceCapacityMap).length) {
      label = "Capacity";
      renderedApps.push(
        <AppResources
          key={serviceName}
          capacityMap={serviceCapacityMap}
          capacity={selectedCapacity}
          currentCapacity={currentCapacity}
          onCapacitySelect={onCapacitySelect}
          disabled={disabled}
          appId={""}
        />,
      );
    }
  }
  if (ticketDetails && deploymentStatus) {
    const { extraAttr = {}, jsonSchema = {} } = ticketDetails;
    const { attributes = {}, resources } = deploymentStatus;
    const { selfServiceType = "" } = extraAttr;
    const hasCluster = _.has(jsonSchema, "formData.cluster");
    if (hasCluster) {
      const cluster = _.get(jsonSchema, "formData.cluster");
      const {
        capacity,
        dnsName,
        extraDnsName,
        extraDnsApps = [],
        nameserver,
      } = cluster;
      const {
        serviceTitle = "",
        serviceVersion = "",
        serviceIcon = "",
        serviceDescription = "",
        suiteApps,
        suiteTitles = {},
        suiteVersions = {},
        suiteIcons = {},
        suiteDescriptions = {},
      } = attributes;

      const extraDnsMap = {};
      extraDnsApps.forEach((item) => {
        extraDnsMap[item.appId] = item;
      });

      let url = `https://${dnsName}.${nameserver}`;
      let extraUrl = extraDnsName ? `https://${extraDnsName}` : null;
      let appId = selfServiceType;
      let title = `${serviceTitle || ""} ${serviceVersion || ""}`;
      let description = serviceDescription || "";
      let icon = serviceIcon || "";
      let iconPath = icon
        ? `${CDN_SERVER}/self-service/${selfServiceType}/.service-config/${icon}`
        : null;
      if (suiteApps) {
        // multiple apps deployment
        for (let subApp in suiteApps) {
          if (subApp === "default") {
            url = `https://${dnsName}.${nameserver}`;
          } else {
            url = `https://${subApp}.${dnsName}.${nameserver}`;
          }
          extraUrl = extraDnsMap[subApp]
            ? `https://${extraDnsMap[subApp].host}`
            : null;
          appId = suiteApps[subApp];
          title = `${suiteTitles[subApp] || ""} ${suiteVersions[subApp] || ""}`;
          description = suiteDescriptions[subApp] || "";
          icon = suiteIcons[subApp] || "";
          iconPath = icon
            ? `${CDN_SERVER}/self-service/${selfServiceType}/.service-config/${icon}`
            : null;
          const appItem = (
            <AppItem
              key={subApp}
              URL={url}
              extraURL={extraUrl}
              appId={appId}
              title={title}
              description={description}
              iconPath={iconPath}
              resourcesLabel={resourcesLabel}
              capacity={capacity}
              resources={resources}
              currentCapacity={currentCapacity}
            />
          );
          if (subApp === "default") {
            renderedApps.unshift(appItem);
          } else {
            renderedApps.push(appItem);
          }
        }
      } else {
        // single app deployment
        renderedApps.push(
          <AppItem
            key={"default"}
            URL={url}
            extraURL={extraUrl}
            appId={appId}
            title={title}
            description={description}
            iconPath={iconPath}
            resourcesLabel={resourcesLabel}
            capacity={capacity}
            resources={resources}
            currentCapacity={currentCapacity}
          />,
        );
      }
    }
  }
  if (renderedApps.length) {
    return (
      <Collapsable
        label={label}
        collapsed={collapsed}
        small={true}
        disabled={disabled}
      >
        {renderedApps}
      </Collapsable>
    );
  }
  return null;
};

export const SolutionItem = (props) => {
  const { org } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  const [collapsed, setCollapsed] = useState(props.collapsed);
  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);
  const {
    selected,
    solutionItem = {},
    selectedCapacity = "x-small",
    onClick = () => {
      setCollapsed(!collapsed);
    },
    onCapacitySelect = (capacity) => {},
    disabled,
    hideTrialPeriod,
    ticketDetails,
  } = props;
  const {
    serviceName = "",
    serviceAttributes = {},
    servicePlans = {},
  } = solutionItem;
  const planItem = servicePlans[selectedCapacity] || {};
  const {
    planDiscount = 0,
    planPrice = 0,
    trialDuration = 0,
    currencySymbol = "$",
  } = planItem;
  const {
    serviceTitle = "",
    serviceVersion = "",
    serviceIcon = "",
    serviceDescription = "",
  } = serviceAttributes;
  const monthlyPrice = planPrice - planDiscount;
  const classNames = ["service-item"];
  selected && classNames.push("selected");
  const trialDurationLabel =
    org.exempt || selectedCapacity !== "x-small"
      ? "N/A"
      : planItem
        ? trialDuration
          ? `${trialDuration}-day`
          : ""
        : "";
  const feeLabel = org.exempt
    ? "FREE"
    : planItem
      ? monthlyPrice
        ? `${currencySymbol}${formatNumber(monthlyPrice)}`
        : ""
      : "";
  return (
    <AppDescription
      title={`${serviceTitle} ${serviceVersion}`}
      description={serviceDescription}
      iconPath={`${CDN_SERVER}/self-service/${serviceName}/.service-config/${serviceIcon}`}
      classNames={classNames}
      onClick={() => {
        !disabled && onClick();
      }}
      extraDescription={
        <>
          <Row className="mt-3 mb-2">
            <Col sm="auto" className="text-nowrap">
              <Label>
                MONTHLY FEE: <strong>{feeLabel}</strong>
              </Label>
            </Col>
            {!hideTrialPeriod ? (
              <Col sm="auto" className="text-nowrap">
                <Label>
                  TRIAL PERIOD: <strong>{trialDurationLabel}</strong>
                </Label>
              </Col>
            ) : null}
          </Row>
          {!hideTrialPeriod ? (
            <Row>
              <Col>
                {selectedCapacity !== "x-small" && !org.exempt ? (
                  <Alert color="warning">
                    Trial deployments are restricted to <strong>X-Small</strong>{" "}
                    capacity only. For a comprehensive experience you're
                    encouraged to <strong>SUBSCRIBE & INSTALL</strong> this
                    deployment.
                  </Alert>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </>
      }
    >
      <SolutionApps
        ticketDetails={ticketDetails}
        solutionItem={solutionItem}
        selectedCapacity={selectedCapacity}
        onCapacitySelect={onCapacitySelect}
        collapsed={collapsed}
        disabled={disabled}
      />
    </AppDescription>
  );
};
