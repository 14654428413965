import {
  ADDAUTHORIZEDUSER,
  ADDCOMMENT,
  ADDNEWPAYMENTCARD,
  ASSIGNCLIENTTOFTA,
  ASSIGNTICKETTOAGENT,
  ATTACHMENT,
  CREATEDEPLOYMENT,
  CREATETICKET,
  CREATEUSER,
  DELETEORGANIZATION,
  FORGOTPASSWORD,
  LOGOUT,
  RESENDVERIFICATIONEMAIL,
  RESETPASSWORD,
  SENDHELPQUESTION,
  SUBSCRIBEDEPLOYMENT,
  SWITCHORG,
  TOGGLEADMINROLE,
  TOGGLEEXEMPT,
  TOGGLEUSERAUTH,
  UPDATEDEPLOYMENT,
  UPDATEMYPROFILE,
  UPDATEORGANIZATION,
  UPDATETICKET,
  VERIFYPASSWORD,
} from "#Graphql/index";
import { client } from "#hoc/config";

export const submitLogout = async () => {
  try {
    const result = await client.mutate({
      mutation: LOGOUT,
      variables: {},
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitSwitchOrg = async ({ orgId }) => {
  try {
    const result = await client.mutate({
      mutation: SWITCHORG,
      variables: { orgId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitAssignTicketToAgent = async ({ ticketId, agentId }) => {
  try {
    const result = await client.mutate({
      mutation: ASSIGNTICKETTOAGENT,
      variables: { ticketId, agentId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitUpdateTicket = async ({ ticketId, input }) => {
  try {
    const result = await client.mutate({
      mutation: UPDATETICKET,
      variables: { ticketId, input },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitUpdateMyProfile = async ({ input }) => {
  try {
    const result = await client.mutate({
      mutation: UPDATEMYPROFILE,
      variables: { input },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitResendVerificationEmail = async () => {
  try {
    const result = await client.mutate({
      mutation: RESENDVERIFICATIONEMAIL,
      variables: {},
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitResetPassword = async ({
  captchaToken,
  token,
  newPassword,
}) => {
  try {
    const result = await client.mutate({
      mutation: RESETPASSWORD,
      variables: { captchaToken, token, newPassword },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitForgotPassword = async ({ captchaToken, email }) => {
  try {
    const result = await client.mutate({
      mutation: FORGOTPASSWORD,
      variables: { captchaToken, email },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitVerifyPassword = async ({ password }) => {
  try {
    const result = await client.mutate({
      mutation: VERIFYPASSWORD,
      variables: { password },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitUpdateOrganization = async ({ input }) => {
  try {
    const result = await client.mutate({
      mutation: UPDATEORGANIZATION,
      variables: { input },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitCreateUser = async ({ input }) => {
  try {
    const result = await client.mutate({
      mutation: CREATEUSER,
      variables: { input },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitAddAuthorizedUser = async ({ userId, role }) => {
  try {
    const result = await client.mutate({
      mutation: ADDAUTHORIZEDUSER,
      variables: { userId, role },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitToggleUserAuth = async ({
  userId,
  enabled,
  deleted,
  authorizedUser,
}) => {
  try {
    const result = await client.mutate({
      mutation: TOGGLEUSERAUTH,
      variables: { userId, enabled, deleted, authorizedUser },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitToggleAdminRole = async ({
  userId,
  admin,
  authorizedUser,
}) => {
  try {
    const result = await client.mutate({
      mutation: TOGGLEADMINROLE,
      variables: { userId, admin, authorizedUser },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitSendHelpQuestion = async ({ question, description }) => {
  try {
    const result = await client.mutate({
      mutation: SENDHELPQUESTION,
      variables: { question, description },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitCreateDeployment = async ({
  deploymentData,
  paymentMethodNonce,
}) => {
  try {
    const result = await client.mutate({
      mutation: CREATEDEPLOYMENT,
      variables: { deploymentData, paymentMethodNonce },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitUpdateDeployment = async ({
  planId,
  jsonSchema,
  deploymentId,
}) => {
  try {
    const result = await client.mutate({
      mutation: UPDATEDEPLOYMENT,
      variables: { planId, jsonSchema, deploymentId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitSubscribeDeployment = async ({
  planId,
  jsonSchema,
  deploymentId,
  paymentMethodNonce,
}) => {
  try {
    const result = await client.mutate({
      mutation: SUBSCRIBEDEPLOYMENT,
      variables: { planId, jsonSchema, deploymentId, paymentMethodNonce },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitCreateTicket = async ({ input }) => {
  try {
    const result = await client.mutate({
      mutation: CREATETICKET,
      variables: { input },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitAssignClientToFTA = async ({ orgId, userId }) => {
  try {
    const result = await client.mutate({
      mutation: ASSIGNCLIENTTOFTA,
      variables: { orgId, userId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitToggleExempt = async ({ orgId }) => {
  try {
    const result = await client.mutate({
      mutation: TOGGLEEXEMPT,
      variables: { orgId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const submitDeleteOrganization = async ({ orgId }) => {
  try {
    const result = await client.mutate({
      mutation: DELETEORGANIZATION,
      variables: { orgId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitUploadAttachment = async ({
  originalName,
  fileType,
  file,
  fileSize,
  ticketId,
}) => {
  try {
    const result = await client.mutate({
      mutation: ATTACHMENT,
      variables: { originalName, fileType, file, fileSize, ticketId },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitAddComment = async ({ ticketId, comment }) => {
  try {
    const result = await client.mutate({
      mutation: ADDCOMMENT,
      variables: { ticketId, comment },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
export const submitAddNewPaymentCard = async ({
  userId,
  paymentMethodNonce,
}) => {
  try {
    const result = await client.mutate({
      mutation: ADDNEWPAYMENTCARD,
      variables: { userId, paymentMethodNonce },
    });
    return result?.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
