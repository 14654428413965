import { gql } from "@apollo/client";

export const CREATEDEPLOYMENT = gql`
  mutation createDeployment(
    $deploymentData: JSON!
    $paymentMethodNonce: String
  ) {
    createDeployment(
      deploymentData: $deploymentData
      paymentMethodNonce: $paymentMethodNonce
    )
  }
`;

export const SUBSCRIBEDEPLOYMENT = gql`
  mutation subscribeDeployment(
    $planId: String!
    $jsonSchema: JSON!
    $deploymentId: Int!
    $paymentMethodNonce: String!
  ) {
    subscribeDeployment(
      planId: $planId
      jsonSchema: $jsonSchema
      deploymentId: $deploymentId
      paymentMethodNonce: $paymentMethodNonce
    )
  }
`;

export const UPDATEDEPLOYMENT = gql`
  mutation updateDeployment(
    $planId: String!
    $jsonSchema: JSON!
    $deploymentId: Int!
  ) {
    updateDeployment(
      planId: $planId
      jsonSchema: $jsonSchema
      deploymentId: $deploymentId
    )
  }
`;
