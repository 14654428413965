import CompanyLogo from "#Components/FileUploader/LogoUploader";
import InputForm, { SubmitButton, TextField } from "#Components/InputForm";
import { CREATEAUTHORIZEDORGANIZATION } from "#Graphql";
import { validate } from "#pages/Profile/MyOrganizations/helpers";
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const AddOrganization = ({ alert, onAddSuccess = (x) => {} }) => {
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [tempLogo, setTempLogo] = useState(null);
  const [createAuthorizedOrganization, { data, loading, error }] = useMutation(
    CREATEAUTHORIZEDORGANIZATION,
  );
  const toggleAddDialog = () => {
    setShowAddDialog(!showAddDialog);
  };

  useEffect(() => {
    if (data?.createAuthorizedOrganization) {
      const { code, message, org } = data.createAuthorizedOrganization;
      if (code === 200) {
        toggleAddDialog();
        alert.success(message);
        onAddSuccess(org);
      } else {
        message && alert.error(message);
      }
    }
  }, [data]);

  useEffect(() => {
    error && alert.error(error.message);
  }, [error]);

  const onFileUpload = (uploadedFiles) => {
    setTempLogo(uploadedFiles[0]);
  };

  const onSubmit = async ({ formFields }) => {
    await createAuthorizedOrganization({
      variables: {
        input: {
          ...formFields,
          logo: tempLogo,
        },
      },
    });
  };

  return (
    <div className="d-flex justify-content-end">
      <Modal isOpen={showAddDialog} backdrop={true} toggle={toggleAddDialog}>
        <ModalHeader toggle={toggleAddDialog}>Add Organization</ModalHeader>
        <InputForm validator={validate} onSubmit={onSubmit}>
          <ModalBody className="company-info">
            <Row>
              <Col sm="12">
                <FormGroup className="logo">
                  <div className="profile-image">
                    <i
                      title="Organization Logo"
                      style={{ fontSize: "3.5rem" }}
                      className="fa-regular fa-building"
                    />
                  </div>
                  <CompanyLogo onFileUpload={onFileUpload} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label for="CompanyName">Name *</Label>
                  <TextField id="CompanyName" name="name" />
                </FormGroup>
                <FormGroup>
                  <Label for="CompanyEmail">Email *</Label>
                  <TextField id="CompanyEmail" name="email" />
                </FormGroup>
                <FormGroup>
                  <Label for="CompanyWebsite">Website</Label>
                  <TextField id="CompanyWebsite" name="website" />
                </FormGroup>
                <FormGroup>
                  <Label for="CompanyPhone">Phone</Label>
                  <TextField id="CompanyPhone" name="phone" />
                </FormGroup>
                <FormGroup>
                  <Label for="CompanyFax">Fax</Label>
                  <TextField id="CompanyFax" name="fax" />
                </FormGroup>
                <FormGroup>
                  <Label for="CompanyAddress">Address</Label>
                  <TextField id="CompanyAddress" name="address" />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggleAddDialog}>
              Cancel
            </Button>
            <SubmitButton color="primary" disabled={loading}>
              Add
            </SubmitButton>
          </ModalFooter>
        </InputForm>
      </Modal>
      <Button size="sm" color="primary" outline onClick={toggleAddDialog}>
        <span className="fa-stack fa-1x">
          <i
            className="fa-regular fa-building fa-stack-2x"
            data-fa-transform="left-6"
          />
          <i
            className="fa-solid fa-plus fa-stack-1x"
            data-fa-transform="right-8 up-4"
          />
        </span>{" "}
        Add Organization
      </Button>
    </div>
  );
};

export default withAlert()(AddOrganization);
