const DeploymentLogs = (props) => {
  const { deploymentLogs } = props;
  return (
    <div className="p-2 logs-scroll">
      {deploymentLogs ? (
        <pre>{deploymentLogs}</pre>
      ) : (
        <div>No logs found for this ticket</div>
      )}
    </div>
  );
};
export default DeploymentLogs;
