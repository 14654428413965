import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const withRouter = (Component) => (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const history = {
    push: (path, state) => {
      navigate(`${path}${location.hash}`, { state });
    },
    goBack: () => navigate(-1),
  };
  return (
    <Component
      history={history}
      location={{
        ...location,
        searchParams,
        setSearchParams,
      }}
      params={params}
      {...props}
    />
  );
};

export default withRouter;
