import InputForm, {
  PasswordField,
  SubmitButton,
  TextField,
} from "#Components/InputForm";
import Logo from "#Components/Logo.jsx";
import { LOGIN } from "#Graphql";
import withRouter from "#hoc/withRouter";

import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect } from "react";
import { withAlert } from "react-alert";
import { Link } from "react-router-dom";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { getLoginValidator } from "./helpers";

const urlParams = new URLSearchParams(window.location.search);
const verifiedEmail = urlParams.get("verifiedEmail");

const Login = (props) => {
  const [login, { data: loginData, loading: isLoading, error: loginError }] =
    useMutation(LOGIN);
  const validate = getLoginValidator(
    verifiedEmail ? ["password"] : ["email", "password"],
  );

  useEffect(() => {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object") || null);
    if (clvObj) {
      props.history.push("/");
      return;
    }
    sessionStorage.setItem("Clouve.object", "");
  }, []);

  useEffect(() => {
    if (loginData?.login?.user) {
      sessionStorage.setItem("Clouve.object", JSON.stringify(loginData.login));
      const { user } = loginData.login;
      props.alert.success(
        `${user.firstName} ${user.lastName} authenticated successfully!`,
      );
      props.history.push("/");
    }
  }, [loginData]);

  useEffect(() => {
    loginError && props.alert.error(loginError.message || "Unknown Error");
  }, [loginError]);

  const loginFormSubmit = async ({ formFields }) => {
    if (!isLoading) {
      const { email, password } = formFields;
      grecaptcha.enterprise.ready(async () => {
        const captchaToken = await grecaptcha.enterprise.execute(
          VITE_GOOGLE_RECAPTCHA_ID,
          { action: "login" },
        );
        const loginTime = moment().format();
        login({
          variables: { captchaToken, email, password, loginTime },
        });
      });
    }
  };

  const classNames = ["login", "form"];
  isLoading && classNames.push("spinner");
  return (
    <div className={classNames.join(" ")}>
      <Logo />
      <h5 className="text-center m-4">Welcome back</h5>
      {verifiedEmail && (
        <h6 className="text-center">Thanks for verifying your email</h6>
      )}
      <InputForm validator={validate} onSubmit={loginFormSubmit}>
        <FormGroup>
          <Label for="loginEmail">Email</Label>
          {verifiedEmail ? (
            <TextField
              id="loginEmail"
              name="email"
              type="text"
              disabled={true}
              value={verifiedEmail}
            />
          ) : (
            <TextField id="loginEmail" name="email" type="text" />
          )}
        </FormGroup>
        <FormGroup className="position-relative">
          <Label for="loginPassword">Password</Label>
          <PasswordField id="loginPassword" name="password" />
          <div className="text-end mt-1">
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </div>
        </FormGroup>
        <FormGroup className="mb-3">
          <SubmitButton
            size="lg"
            color="primary"
            disabled={isLoading}
            block={true}
          >
            Login
          </SubmitButton>
        </FormGroup>
      </InputForm>
      <Row>
        <Col className="text-center create-account">
          <span>Don't have a Clouve account? </span>
          <Link to={"/signup"}>Sign up now!</Link>
        </Col>
      </Row>
    </div>
  );
};

export default withAlert()(withRouter(Login));
