export const TICKET_STATES = {
  open: "open",
  inProgress: "in_progress",
  in_progress: "in_progress",
  resolved: "resolved",
  closed: "closed",
};
export const TICKET_TYPES = {
  deployment: "deployment",
  support: "support",
};
