import { subscriptionMap } from "#Components/AutoDeployment";
import DeploymentApps from "#Components/AutoDeployment/DeploymentApps";
import {
  camelToTitle,
  getRemainingTime,
  isSubscriptionStatusAny,
  isTrialExpired,
} from "#hoc/util";
import moment from "moment";
import { Alert, Badge, Table } from "reactstrap";

const DeploymentStatus = (props) => {
  const {
    ticketDetails,
    selfServiceBuildStatus = {},
    deploymentStatus,
    onShowSettingsClick = () => {},
  } = props;
  const isStatusAny = (statusArr = []) => {
    let { buildStatus, selfServiceAction } = selfServiceBuildStatus;
    const { deployment = {} } = ticketDetails;
    return (
      isSubscriptionStatusAny(deployment, statusArr) &&
      !(selfServiceAction + "").match(/^delete$/gi) &&
      (buildStatus + "").match(/^success$/gi)
    );
  };

  const { deployment = {} } = ticketDetails;
  const { actionInProgress, actionSuccess } = selfServiceBuildStatus;
  let { subscriptionStatus = "", subscriptionDetails } = deployment;
  subscriptionStatus = (subscriptionStatus + "").split("_").join(" ");
  let subscriptionStatusColor =
    subscriptionMap[(subscriptionStatus + "").toLowerCase()];
  if (isTrialExpired(deployment)) {
    subscriptionStatus = "Trial Expired";
    subscriptionStatusColor = subscriptionMap["expired"];
  }
  const labelWidth = 150;
  const {
    remainingDays,
    remainingHours,
    expireDate: trialExpireDate,
  } = getRemainingTime(deployment.trialExpiresAt);

  const badgeColor = remainingHours > 0 ? "warning" : "danger";

  const renderSubscriptionDetails = (obj) => {
    return Object.keys(obj).map((key, idx) => (
      <tr key={idx}>
        <th width={labelWidth}>{camelToTitle(key)}:</th>
        <td>
          {isNaN(Date.parse(obj[key]))
            ? obj[key] || ""
            : moment(new Date(obj[key])).utc().format("MMM DD, YYYY h:mm A")}
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Table size="sm" borderless>
        <tbody>
          <tr>
            <th width={labelWidth}>Subscription:</th>
            <td>
              <Badge color={subscriptionStatusColor}>
                {(subscriptionStatus + "").toUpperCase()}
              </Badge>
            </td>
          </tr>
          {isSubscriptionStatusAny(deployment, ["trial"]) ? (
            <>
              {renderSubscriptionDetails({
                startDate: deployment.createdAt,
              })}
              <tr>
                <th width={labelWidth}>Expires in:</th>
                <td>
                  {isTrialExpired(deployment) ? (
                    <Badge color={badgeColor}>EXPIRED</Badge>
                  ) : (
                    <>
                      <Badge
                        className="text-dark"
                        color={badgeColor}
                        title={trialExpireDate}
                      >
                        {remainingDays > 0 ? (
                          <>{remainingDays} day(s)</>
                        ) : (
                          <>{remainingHours} hour(s)</>
                        )}
                      </Badge>
                    </>
                  )}
                </td>
              </tr>
            </>
          ) : null}
          {isSubscriptionStatusAny(deployment, ["active", "pending"])
            ? renderSubscriptionDetails({
                startDate: subscriptionDetails?.createdAt,
                nextBillingDate: subscriptionDetails?.nextBillingDate,
                nextBillAmount: `$${subscriptionDetails?.nextBillAmount}`,
              })
            : null}
          {isSubscriptionStatusAny(deployment, ["trial_canceled"])
            ? renderSubscriptionDetails({
                cancellationDate: deployment?.updatedAt,
              })
            : null}
          {isSubscriptionStatusAny(deployment, ["canceled"])
            ? renderSubscriptionDetails({
                cancellationDate: subscriptionDetails?.updatedAt,
              })
            : null}
          {!actionInProgress && deploymentStatus && actionSuccess ? (
            <DeploymentApps
              labelWidth={labelWidth}
              ticketDetails={ticketDetails}
              deploymentStatus={deploymentStatus}
              onShowSettingsClick={onShowSettingsClick}
            />
          ) : null}
          {isStatusAny(["trial"]) ? (
            <tr>
              <td colSpan={2}>
                <Alert color="warning">
                  {isTrialExpired(deployment) ? (
                    <>
                      The trial period of this app has come to an end, but the
                      data from your trial period remains accessible. To keep
                      using the app, kindly subscribe.
                    </>
                  ) : (
                    <>
                      This app is currently in its trial period and will be
                      temporarily halted upon its conclusion. To continue using
                      the app, please consider subscribing.
                    </>
                  )}
                </Alert>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
      {isStatusAny(["active", "pending"]) ? (
        <Alert color="warning" className="mt-3">
          Please be aware that in the event of canceling your subscription, you
          will only be refunded for the remaining days of the current billing
          period.
        </Alert>
      ) : null}
    </>
  );
};
export default DeploymentStatus;
