import { SolutionApps } from "#Components/SolutionItem";
import { useState } from "react";
import { Alert } from "reactstrap";

const PasswordField = (props) => {
  const { value } = props;
  const [showValue, setShowValue] = useState(false);

  const toggleShowValue = () => {
    setShowValue(!showValue);
  };

  return (
    <>
      <span className="password-mask">
        {showValue ? (
          atob(value)
        ) : (
          <>
            {"\uFF0A"}
            {"\uFF0A"}
            {"\uFF0A"}
            {"\uFF0A"}
            {"\uFF0A"}
          </>
        )}
      </span>
      <span className="password-show" onClick={toggleShowValue}>
        <i
          className={
            showValue ? "fa fa-eye-slash ms-2" : "fa fa-eye-slash ms-2 d-none"
          }
        />
        <i className={showValue ? "fa fa-eye ms-2 d-none" : "fa fa-eye ms-2"} />
      </span>
    </>
  );
};

const DeploymentApps = (props) => {
  const {
    labelWidth,
    ticketDetails,
    deploymentStatus = {},
    onShowSettingsClick = () => {},
  } = props;
  const { jsonSchema = {} } = ticketDetails;
  const { formData = {} } = jsonSchema;
  const { config = {}, cluster = {} } = formData;
  const { dnsName, extraDnsName } = cluster;
  const { attributes = {}, passwords = [] } = deploymentStatus;
  const { usernameFields = [] } = attributes || {};

  //processing usernames
  const usernames = usernameFields.map((f) => ({
    key: f,
    value: config[f],
  }));

  return (
    <>
      {usernames.length ? (
        <>
          <tr>
            <td colSpan={2}>
              <hr />
            </td>
          </tr>
          <tr>
            <th width={labelWidth}>Username/Email:</th>
            <td>
              {usernames.map(({ value }) => value).join(", ")} (
              <span className="inline-link" onClick={onShowSettingsClick}>
                Settings
              </span>
              )
            </td>
          </tr>
        </>
      ) : null}
      {passwords.length ? (
        <>
          <tr>
            <th width={labelWidth}>Passwords:</th>
            <td>
              {passwords.map((p, i) => (
                <PasswordField key={i} value={p} />
              ))}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Alert color="warning">
                Clouve's automatically generated passwords remain valid only
                until the user modifies them within each specific application.
              </Alert>
            </td>
          </tr>
        </>
      ) : null}
      <tr>
        <td colSpan={2}>
          <hr />
        </td>
      </tr>
      {dnsName || extraDnsName ? (
        <tr>
          <td colSpan={2}>
            <div className="endpoints">
              <SolutionApps
                ticketDetails={ticketDetails}
                deploymentStatus={deploymentStatus}
                label="Installed Apps"
                resourcesLabel="Capacity Limits"
              />
            </div>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default DeploymentApps;
