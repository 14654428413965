import { gql } from "@apollo/client";

export const CLOUVEAGENTSLIST = gql`
  query clouveAgentsList($agentRole: String!) {
    clouveAgentsList(agentRole: $agentRole) {
      id
      firstName
      lastName
      email
      photo
      enabled
      organizationRole {
        id
        role
      }
    }
  }
`;
