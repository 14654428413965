import { Input, InputGroup, InputGroupText, Label } from "reactstrap";
import { getServiceHosts } from "./helpers";

export default (props) => {
  const { schema, registry, formContext, className = "" } = props;
  const { postfix } = schema;
  const { widgets } = registry;
  const { TextWidget } = widgets;
  const { ticketDetails, formData = {} } = formContext;
  const { attributes: serviceAttributes = {} } = ticketDetails;
  const { cluster = {} } = formData;
  const defaultDnsName = `${cluster?.dnsName?.toLowerCase()}.${cluster?.nameserver}`;
  const { serviceHosts } = getServiceHosts(defaultDnsName, serviceAttributes);
  const classNames = className.split(" ");
  classNames.push(...["form-control", "text-end"]);
  return (
    <div className="dns-widget">
      <InputGroup>
        <TextWidget {...props} className={classNames.join(" ")} />
        <InputGroupText>{postfix}</InputGroupText>
      </InputGroup>
      {serviceHosts.length > 1 ? (
        <>
          {serviceHosts.map((appItem) => (
            <div key={appItem?.appId} className="mt-3">
              <Label>{appItem?.appTitle}</Label>
              <Input value={appItem?.host} disabled />
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};
