import InputForm, { SelectField, SubmitButton } from "#Components/InputForm";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateTicket } from "#Graphql/mutate";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Button, Spinner, Tooltip } from "reactstrap";

export const validate = (values) => {
  const errors = {};
  const requiredFields = ["estimatedTimeValue"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = " ";
    }
  });

  if (
    values.estimatedTimeValue &&
    !/^\d*\.?\d*$/i.test(values.estimatedTimeValue)
  ) {
    errors.estimatedTimeValue = " ";
  }
  return errors;
};

class TicketEstimate extends PureComponent {
  state = {
    initialValues: {},
    tooltipOpen: false,
    isLoading: false,
    isOpen: false,
  };

  componentDidMount() {
    this.setState({ initialValues: this.getInitialValues() });
  }

  toggle = () => {
    let { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  };

  componentDidUpdate(prevProps) {
    const { ticketDetails, editableFields } = this.props;
    if (editableFields.isEditableField !== "estimatedTime") {
      this.setState({ isOpen: false });
    }
    if (
      ticketDetails?.estimatedTime !== prevProps.ticketDetails?.estimatedTime
    ) {
      this.setState({ initialValues: this.getInitialValues() });
    }
  }

  getInitialValues = () => {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    if (ticketDetails && ticketDetails.estimatedTime) {
      const { estimatedTime = "", estimatedStatus = "" } = ticketDetails;
      const firstStr = estimatedTime.substr(0, estimatedTime.indexOf(" "));
      const secondStr = estimatedTime.substr(estimatedTime.indexOf(" "));
      const daytime = secondStr.split(" ");
      const daytimeB = daytime[1];

      return {
        estimatedTimeValue: firstStr,
        estimatedTimeUnit: daytimeB ? daytimeB : "Day",
        estimatedStatus: estimatedStatus
          ? estimatedStatus
          : userRoleInOrg.isAgentUser
            ? "pending"
            : "approved",
      };
    } else {
      return {};
    }
  };

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleEstimatedStatus = async (newEstimatedStatus) => {
    await this.estimatedTimeSubmit({
      formFields: {
        estimatedStatus: newEstimatedStatus,
      },
    });
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  estimatedTimeSubmit = async ({ formFields }) => {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails, onUpdate = () => {} } = this.props;
    this.setState({ isLoading: true });

    const initialValues = this.getInitialValues();
    const { estimatedTimeValue, estimatedTimeUnit, estimatedStatus } = {
      ...initialValues,
      ...formFields,
    };
    const input = {
      estimatedTime: estimatedTimeValue + " " + estimatedTimeUnit,
      estimatedStatus: userRoleInOrg.isAgentUser ? "pending" : estimatedStatus,
    };

    try {
      const { updateTicket } = await submitUpdateTicket({
        ticketId: ticketDetails.id,
        input,
      });
      if (updateTicket && updateTicket.code === 200) {
        this.showSuccessMessage(updateTicket.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(updateTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  estimatedStatusTooltip = (ticketDetails) => {
    return ticketDetails.estimatedStatus.toUpperCase();
  };

  renderEstimate = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { initialValues, isOpen, isLoading } = this.state;
    const isPending = ticketDetails.estimatedStatus === "pending";
    const isApproved = ticketDetails.estimatedStatus === "approved";
    const canEdit =
      ticketDetails?.state === TICKET_STATES.open && !isPending && !isApproved;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isAgentAdmin && isPending) {
      return (
        <div className="not-editable-block">
          <span>{ticketDetails.estimatedTime} </span>
          <div className="d-flex ar-btn-group resolution-btn btn-ar">
            <Button
              color="success"
              size="sm"
              onClick={() => this.handleEstimatedStatus("approved")}
            >
              Approve
            </Button>
            <Button
              color="link"
              size="sm"
              onClick={() => this.handleEstimatedStatus("rejected")}
            >
              Reject
            </Button>
          </div>
        </div>
      );
    }

    if (userRoleInOrg.isAgent && canEdit) {
      return (
        <div className="editable-block">
          {isOpen ? (
            <div className="editable-field estimate-container">
              <InputForm
                initialValues={initialValues}
                onSubmit={this.estimatedTimeSubmit}
                validator={validate}
              >
                <SelectField className="amount" name="estimatedTimeValue">
                  {Array.from({ length: 23 }, (_, i) => i + 1).map((v) => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
                </SelectField>
                <SelectField className="unit" name="estimatedTimeUnit">
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </SelectField>
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("estimatedTime")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : (
            <span
              className="hover-edit"
              onClick={() => this.handleEditFields("estimatedTime")}
              title="Click here to edit"
            >
              {ticketDetails.estimatedTime}
              <i className="fa fa-pencil" />
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div className="not-editable-block">{ticketDetails.estimatedTime} </div>
      );
    }
  };

  renderEstimatedStatus = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { isOpen, tooltipOpen } = this.state;
    if (userRoleInOrg.isAgent && ticketDetails?.estimatedStatus && !isOpen) {
      return (
        <div className="estimate-status" id="EstimatedStatus">
          {ticketDetails.estimatedStatus === "approved" && (
            <span className="approved">A</span>
          )}
          {ticketDetails.estimatedStatus === "pending" && (
            <span className="pending">P</span>
          )}
          {ticketDetails.estimatedStatus === "rejected" && (
            <span className="reject">R</span>
          )}
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target="EstimatedStatus"
            toggle={this.toggle}
          >
            <span className="tooltip-text">
              {this.estimatedStatusTooltip(ticketDetails)}
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  render() {
    return (
      <div className="position-relative">
        {this.renderEstimate()}
        {this.renderEstimatedStatus()}
      </div>
    );
  }
}

export default withAlert()(TicketEstimate);
