import { emailRegex, phoneRegex, websiteRegex } from "#hoc/util";

export const validate = async (values) => {
  const errors = {};
  const requiredFields = ["name", "email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  if (values.email && !emailRegex.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (values.website && !websiteRegex.test(values.website)) {
    errors.website = "Invalid website address";
  }
  if (values.phone && !phoneRegex.test(values.phone)) {
    errors.phone = "Invalid phone number";
  }
  if (values.fax && !phoneRegex.test(values.fax)) {
    errors.fax = "Invalid fax number";
  }
  return errors;
};
