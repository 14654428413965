import { TICKET_STATES } from "#Constants/TicketFields";
import { fetchSnapShot } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import { getTicketsLocation } from "#pages/Tickets";
import React, { PureComponent } from "react";
import { Card, Col, Row } from "reactstrap";

class Snapshot extends PureComponent {
  state = {
    orgSnapshot: {},
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, assignedTo } = this.props;
    if (
      location &&
      prevProps.location &&
      location.pathname !== prevProps.location.pathname
    ) {
      this.loadData();
    }
    if (assignedTo !== prevProps.assignedTo) {
      this.loadData();
    }
  }

  loadData = () => {
    const { params, assignedTo } = this.props;
    const { orgId } = params;
    fetchSnapShot({ orgId: parseInt(orgId), assignedTo })
      .then(({ orgSnapshot }) => {
        this.setState({ orgSnapshot });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleTicketState = (ticketState) => {
    const { history, params } = this.props;
    history.push(
      getTicketsLocation({
        params,
        overrideParams: { ticketState },
      }),
    );
  };

  render() {
    const { currMonth } = this.props;
    const { orgSnapshot } = this.state;
    return (
      <>
        {orgSnapshot.orgData ? (
          <Row>
            <Col>
              <h5 className="mt-3">
                Client:{" "}
                <span className="brown">{orgSnapshot.orgData.name}</span>
              </h5>
            </Col>
          </Row>
        ) : null}
        <Row className="snapshot">
          <SnapShotItems
            itemClass="sp"
            status="Open"
            value={currMonth ? orgSnapshot.openCurrMonth : orgSnapshot.open}
            onClick={() => this.handleTicketState(TICKET_STATES.open)}
          />
          <SnapShotItems
            itemClass="so"
            status="In Progress"
            value={
              currMonth
                ? orgSnapshot.inProgressCurrMonth
                : orgSnapshot.inProgress
            }
            onClick={() => this.handleTicketState(TICKET_STATES.inProgress)}
          />
          <SnapShotItems
            itemClass="si"
            status="Resolved"
            value={
              currMonth ? orgSnapshot.resolvedCurrMonth : orgSnapshot.resolved
            }
            onClick={() => this.handleTicketState(TICKET_STATES.resolved)}
          />
          <SnapShotItems
            itemClass="su"
            status="Closed"
            value={currMonth ? orgSnapshot.closedCurrMonth : orgSnapshot.closed}
            onClick={() => this.handleTicketState(TICKET_STATES.closed)}
          />
        </Row>
      </>
    );
  }
}

const SnapShotItems = (props) => {
  return (
    <Col className="s-card p-1" onClick={props.onClick}>
      <Card className={"shadow-sm mb-2 bg-white rounded-0 " + props.itemClass}>
        <span>{props.status}</span>
        <span>{props.value === null ? "0" : props.value}</span>
      </Card>
    </Col>
  );
};

export default withRouter(Snapshot);
