import CompanyLogo from "#Components/FileUploader/LogoUploader";
import InputForm, { SubmitButton, TextField } from "#Components/InputForm";
import { submitUpdateOrganization } from "#Graphql/mutate";
import { validate } from "#pages/Profile/MyOrganizations/helpers";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";

class UpdateCompanyDetails extends PureComponent {
  state = { isLoading: false, tempLogo: null };

  onFileUpload = (uploadedFiles) => {
    this.setState({ tempLogo: uploadedFiles[0] });
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
    this.props.onClose();
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  onSaveOrgDetails = async ({ formFields: values }) => {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const { user, org, userRoleInOrg } = clvObj;
    const input = {
      name: values.name,
      email: values.email,
      website: values.website,
      phone: values.phone,
      fax: values.fax,
      address: values.address,
      logo: this.state.tempLogo?.fileUrl || org.logo || "",
    };
    try {
      this.setState({ isLoading: true });
      const { updateOrganization } = await submitUpdateOrganization({ input });
      if (updateOrganization && updateOrganization.code === 200) {
        if (userRoleInOrg.isAuthorizedUser) {
          const authOrg = user.authorizedOrgs.filter(
            (authOrg) => authOrg.id === org.id,
          )[0];
          Object.assign(authOrg, input);
        } else {
          Object.assign(user.organization, input);
        }
        Object.assign(org, input);
        sessionStorage.setItem("Clouve.object", JSON.stringify({ ...clvObj }));

        this.showSuccessMessage(updateOrganization.message);
      } else {
        this.errorHandler(updateOrganization);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { org, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { isLoading } = this.state;
    return (
      <div className="company-info">
        {isLoading && (
          <div className="loading-spinner">
            <Spinner type="grow" />
          </div>
        )}
        <InputForm
          initialValues={org}
          validator={validate}
          onSubmit={this.onSaveOrgDetails}
        >
          <Row>
            <Col sm="12">
              <FormGroup className="logo">
                {org.logo ? (
                  <div
                    className="profile-image"
                    style={{
                      backgroundImage: `url(${org.logo})`,
                    }}
                    title={org.name}
                  />
                ) : (
                  <div className="profile-image">
                    <i
                      title={org.name}
                      style={{ fontSize: "3.5rem" }}
                      className={
                        userRoleInOrg.isAuthorizedUser
                          ? "fa-regular fa-building"
                          : "fa-solid fa-building"
                      }
                    />
                  </div>
                )}
                <CompanyLogo onFileUpload={this.onFileUpload} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label for="CompanyName">Name *</Label>
                <TextField id="CompanyName" name="name" />
              </FormGroup>
              <FormGroup>
                <Label for="CompanyEmail">Email *</Label>
                <TextField id="CompanyEmail" name="email" />
              </FormGroup>
              <FormGroup>
                <Label for="CompanyWebsite">Website</Label>
                <TextField id="CompanyWebsite" name="website" />
              </FormGroup>
              <FormGroup>
                <Label for="CompanyPhone">Phone</Label>
                <TextField id="CompanyPhone" name="phone" />
              </FormGroup>
              <FormGroup>
                <Label for="CompanyFax">Fax</Label>
                <TextField id="CompanyFax" name="fax" />
              </FormGroup>
              <FormGroup>
                <Label for="CompanyAddress">Address</Label>
                <TextField id="CompanyAddress" name="address" />
              </FormGroup>
            </Col>
          </Row>

          <div className="text-end">
            <SubmitButton color="primary" size="sm">
              Save
            </SubmitButton>
            <Button color="link" onClick={() => this.props.onClose()} size="sm">
              Cancel
            </Button>
          </div>
        </InputForm>
      </div>
    );
  }
}

export default withAlert()(UpdateCompanyDetails);
