import { gql } from "@apollo/client";

export const ORGSNAPSHOT = gql`
  query orgSnapshot($orgId: Int, $ticketType: String, $assignedTo: AssignedTo) {
    orgSnapshot(
      orgId: $orgId
      ticketType: $ticketType
      assignedTo: $assignedTo
    ) {
      orgData {
        name
      }
      open
      openCurrMonth
      inProgress
      inProgressCurrMonth
      resolved
      resolvedCurrMonth
      closed
      closedCurrMonth
    }
  }
`;
