import { flatten } from "flat";
import React from "react";
import DataTable from "react-data-table-component";
import { Button, Col, Input, InputGroup, Row, Spinner } from "reactstrap";
import { StyleSheetManager } from "styled-components";

const ToolboxComponent = ({
  filterText,
  onFilter,
  onClear,
  toolboxComponent,
  hideSearch,
}) => (
  <Row className="align-items-center mb-2">
    <Col>
      {hideSearch ? null : (
        <InputGroup size="sm">
          <Input
            id="search"
            type="text"
            placeholder="Search ..."
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
          />
          <Button onClick={onClear} color="primary">
            X
          </Button>
        </InputGroup>
      )}
    </Col>
    <Col>{toolboxComponent}</Col>
  </Row>
);

export const InfoColumn = ({ center, children }) => {
  if (center) {
    return (
      <center>
        <strong>{children}</strong>
      </center>
    );
  }
  return <strong>{children}</strong>;
};

export const InfoTable = (props) => {
  const { children, data, toolboxComponent, hideSearch, ...tableProps } = props;
  const columns = [];
  if (children instanceof Array) {
    columns.push(...children);
  } else {
    columns.push(children);
  }

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredData = data.filter((item) => {
    if (filterText) {
      if (item && typeof item === "object") {
        const flatObj = flatten(item);
        return Object.keys(flatObj)
          .filter(
            (key) =>
              typeof flatObj[key] === "string" ||
              typeof flatObj[key] === "number",
          )
          .some((key) =>
            (flatObj[key] + "")
              .toLowerCase()
              .includes(filterText.toLowerCase()),
          );
      } else if (item && typeof item === "string") {
        return item.toLowerCase().includes(filterText.toLowerCase());
      } else {
        return false;
      }
    }
    return true;
  });

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const mappedColumns = columns
    .filter((column) => column && !!column.props)
    .map((column) => {
      const { children, ...columnProps } = column.props;
      Object.keys(columnProps).forEach((key) => {
        if (typeof columnProps[key] === "boolean") {
          columnProps[key] = columnProps[key] + "";
        }
      });
      return {
        name: column,
        ...columnProps,
      };
    });

  return (
    <StyleSheetManager shouldForwardProp={() => true}>
      <ToolboxComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        toolboxComponent={toolboxComponent}
        hideSearch={hideSearch}
      />
      <DataTable
        {...tableProps}
        columns={mappedColumns}
        data={filteredData}
        paginationResetDefaultPage={resetPaginationToggle}
        progressComponent={<Spinner style={{ margin: "50px" }} />}
        persistTableHead
      />
    </StyleSheetManager>
  );
};
