import moment from "moment";
import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

class OrgDetailsModal extends PureComponent {
  handleOnClose = () => {
    this.props.modaltoggle();
  };

  render = () => {
    const { modal, orgItem } = this.props;
    if (!orgItem) {
      return "";
    }
    return (
      <div>
        <Modal isOpen={modal} toggle={this.handleOnClose}>
          <ModalHeader toggle={this.handleOnClose}>
            Organization Info
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12}>
                <Card>
                  <CardHeader>
                    <h6>{orgItem.name || ""}</h6>
                  </CardHeader>
                  <CardBody>
                    <div className="company-info">
                      <Row>
                        <Col xs="auto">
                          <div className="logo">
                            {orgItem.logo ? (
                              <div
                                className="profile-image"
                                style={{
                                  backgroundImage: `url(${orgItem.logo})`,
                                }}
                                title={orgItem.name}
                              />
                            ) : (
                              <i
                                title={orgItem.name}
                                style={{ fontSize: "4.5rem" }}
                                className="fa-regular fa-building"
                              />
                            )}
                          </div>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Website:</Label>
                            <Input
                              disabled={true}
                              value={orgItem.website || ""}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>Phone:</Label>
                            <Input
                              disabled={true}
                              value={orgItem.phone || ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div>
                      <Label>Last Login:</Label>{" "}
                      <strong>
                        {orgItem.lastLoginAt
                          ? moment(orgItem.lastLoginAt)
                              .utc()
                              .format("MMM DD, YYYY h:mm A")
                          : ""}
                      </strong>
                    </div>
                    <div>
                      <Label>Updated:</Label>{" "}
                      <strong>
                        {orgItem.updatedAt
                          ? moment(orgItem.updatedAt)
                              .utc()
                              .format("MMM DD, YYYY h:mm A")
                          : ""}
                      </strong>
                    </div>
                    <div>
                      <Label>Created:</Label>{" "}
                      <strong>
                        {orgItem.createdAt
                          ? moment(orgItem.createdAt)
                              .utc()
                              .format("MMM DD, YYYY h:mm A")
                          : ""}
                      </strong>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleOnClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
}

export default OrgDetailsModal;
