import React, { useState } from "react";
import UpdateUserDetails from "./UpdateUserDetails";
import ViewUserDetails from "./ViewUserDetails";

export default function MyProfile() {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen ? (
        <UpdateUserDetails onOpen={onOpen} onClose={onClose} />
      ) : (
        <ViewUserDetails onOpen={onOpen} onClose={onClose} />
      )}
    </>
  );
}
