import InputForm, { SelectField, SubmitButton } from "#Components/InputForm";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitUpdateTicket } from "#Graphql/mutate";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Button, Spinner } from "reactstrap";

class TicketStates extends PureComponent {
  state = {
    isLoading: false,
    isOpen: false,
  };

  componentDidUpdate(prevProps) {
    const { editableFields } = this.props;
    if (editableFields.isEditableField !== "status") {
      this.setState({ isOpen: false });
    }
  }

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  updateTicketStateSubmit = async ({ formFields }) => {
    const { ticketDetails, onUpdate = () => {} } = this.props;

    try {
      const { state } = formFields;
      this.setState({ isLoading: true });
      const { updateTicket } = await submitUpdateTicket({
        ticketId: ticketDetails.id,
        input: { state },
      });
      if (updateTicket && updateTicket.code === 200) {
        this.showSuccessMessage(updateTicket.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(updateTicket);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  renderClientStates = () => {
    const { ticketDetails = {} } = this.props;
    if (ticketDetails.state) {
      return (
        <div
          className={`ticket-state state-${ticketDetails.state} d-inline-block`}
        >
          {ticketDetails.state.split("_").join(" ").toUpperCase()}
        </div>
      );
    } else {
      return null;
    }
  };

  renderStates = () => {
    const { states = [] } = this.props;
    return states.map(({ name, value, disabled }) => (
      <option key={value} value={value} disabled={disabled}>
        {name.split("_").join(" ")}
      </option>
    ));
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails, handleSubmit } = this.props;
    const { isOpen, isLoading } = this.state;
    const canEdit = ticketDetails?.state !== TICKET_STATES.closed;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isAgent && canEdit) {
      return (
        <div className="editable-block">
          {isOpen ? (
            <div className="editable-field ticket-state">
              <InputForm
                initialValues={{ state: ticketDetails?.state }}
                onSubmit={this.updateTicketStateSubmit}
              >
                <SelectField name="state">
                  <option value="" disabled>
                    Please select
                  </option>
                  {this.renderStates()}
                </SelectField>
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("status")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : (
            <span
              className="hover-edit"
              onClick={() => this.handleEditFields("status")}
              title="Click here to edit"
            >
              {this.renderClientStates()}
              <i className="fa fa-pencil" />
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div className="not-editable-block">{this.renderClientStates()}</div>
      );
    }
  }
}

export default withAlert()(TicketStates);
