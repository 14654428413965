import { submitUploadAttachment } from "#Graphql/mutate";
import React from "react";
import { Button, Spinner } from "reactstrap";

export default class FileUploader extends React.Component {
  state = {
    uploadedFiles: [],
    isLoading: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { uploadedFiles } = this.state;
    if (prevState.uploadedFiles !== uploadedFiles) {
      const { onFileUpload = (x) => {} } = this.props;
      onFileUpload(uploadedFiles);
    }
  }

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  uploadFile = async () => {
    let { ticketId } = this.props;
    const { uploadedFiles, fileName, fileData, fileSize, fileType } =
      this.state;
    const tmpFileData = fileData.split(",");
    const base64File = tmpFileData.length > 1 ? tmpFileData[1] : fileData;
    const imageSize = fileSize / 1000000;
    if (imageSize > 15) {
      this.errorHandler({ message: "File size can not exceed 15MB" });
    } else {
      try {
        this.setState({ isLoading: true });
        const { uploadAttachment } = await submitUploadAttachment({
          originalName: fileName,
          file: {
            base64File,
          },
          fileType,
          fileSize,
          ticketId,
        });
        if (uploadAttachment) {
          this.setState({
            uploadedFiles: [...uploadedFiles, uploadAttachment],
          });
          this.showSuccessMessage("Attachment uploaded successfully!");
        } else {
          this.errorHandler(uploadAttachment);
          // this.errorHandler({ message: "Failed to upload attachment" });
        }
      } catch (err) {
        this.errorHandler(err);
      }
    }
  };

  handleFileUpload = (event) => {
    const uploadedFiles = event.target.files;
    const uploadedFileNames = Object.keys(uploadedFiles);
    const uploadedFilesData = [];
    uploadedFileNames.map((fileData, index) => {
      return uploadedFilesData.push(uploadedFiles[fileData]);
    });
    uploadedFilesData.map((fileData, index) => {
      fileData.length > 0
        ? this.setState({ validate: true })
        : this.setState({ validate: false });
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        this.setState(
          {
            fileData: reader.result,
            fileName: fileData.name,
            fileSize: fileData.size,
            fileType: fileData.type,
          },
          async () => {
            await this.uploadFile();
          },
        );
      };
      return null;
    });
  };

  renderFileTypePreview = (item) => {
    if (item.fileType === "image/jpeg") {
      return (
        <img width="100%" src={item.fileUrl} alt="" title={item.fileName} />
      );
    }
    if (item.fileType === "image/jpg") {
      return (
        <img width="100%" src={item.fileUrl} alt="" title={item.fileName} />
      );
    }
    if (item.fileType === "image/png") {
      return (
        <img width="100%" src={item.fileUrl} alt="" title={item.fileName} />
      );
    }
    if (item.fileType === "application/pdf") {
      return (
        <i
          title={item.fileName}
          style={{ fontSize: "2rem" }}
          className="fa fa-file-pdf-o"
        />
      );
    }
    if (item.fileType === "") {
      return (
        <i
          title={item.fileName}
          style={{ fontSize: "2rem" }}
          className="fa fa-file-text-o"
        />
      );
    }
  };

  renderAttachmentPreview = () => {
    let { uploadedFiles = [] } = this.state;
    return uploadedFiles.map((item, index) => {
      return (
        <div className="file-preview" key={index}>
          {this.renderFileTypePreview(item)}
          <span>{item.fileName}</span>
          <Button
            className="btn-light"
            onClick={(e) => this.removeAttachment(e, index)}
          >
            <i className="fa fa-times" />
          </Button>
        </div>
      );
    });
  };

  removeAttachment = (e, index) => {
    e.preventDefault();
    const { uploadedFiles } = this.state;
    uploadedFiles.splice(index, 1);
    this.setState({ uploadedFiles });
  };

  renderUploader = () => {
    const { hideUploadedFiles } = this.props;
    const { uploadedFiles } = this.state;
    if (this.state.isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }
    if (!hideUploadedFiles && uploadedFiles.length) {
      return <div className="fileprev"> {this.renderAttachmentPreview()} </div>;
    }
  };

  render = () => {
    return (
      <div className="file-attachment-cm">
        <div className="file-upload">
          <div className="file-upload-wrapper">
            <input
              multiple
              className="file-upload-input"
              type="file"
              name="attachments"
              onChange={this.handleFileUpload}
              id="uploadAttachment"
              accept="image/*, application/msword, application/pdf, .docx, .doc, .pdf, png, jpg, jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            <div className="drop-box">
              <div className="fileprev-uploader">
                <i className="fa fa-cloud" />{" "}
                <span>
                  Drop files to attach, or <span>browse</span>
                </span>
              </div>
            </div>
          </div>
          <div className="drop-box">{this.renderUploader()}</div>
        </div>
        <p className="p-1">File size can not exceed 15MB</p>
      </div>
    );
  };
}
