import { gql } from "@apollo/client";

export const RESETPASSWORD = gql`
  mutation resetPassword(
    $newPassword: String!
    $captchaToken: String
    $token: String
  ) {
    resetPassword(
      newPassword: $newPassword
      captchaToken: $captchaToken
      token: $token
    )
  }
`;
