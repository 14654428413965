import { getUserIcon } from "#hoc/util";
import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

export default class OrgUsersModal extends PureComponent {
  handleOnClose = () => {
    this.props.modaltoggle();
  };

  renderOrgUser = (orgUser, idx) => {
    const orgRole =
      orgUser.authorizedRoles && orgUser.authorizedRoles[0]
        ? {
            ...orgUser.authorizedRoles[0],
            isAuthorizedUser: true,
          }
        : orgUser.organizationRole;
    return (
      <Card key={idx} className="org-user-details">
        <CardHeader>
          <h6>{orgUser.firstName + " " + orgUser.lastName}</h6>
        </CardHeader>
        <CardBody>
          <div className="user-info">
            <Row>
              <Col xs="auto">
                <div className="logo">
                  {orgUser.photo ? (
                    <div
                      className="profile-image"
                      style={{
                        backgroundImage: `url(${orgUser.photo})`,
                      }}
                      title={`${orgUser.firstName} ${orgUser.lastName}`}
                    />
                  ) : (
                    <i
                      title={`${orgUser.firstName} ${orgUser.lastName}`}
                      style={{ fontSize: "4.5rem" }}
                      className={getUserIcon(orgRole)}
                    />
                  )}
                </div>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Email:</Label>
                  <Input disabled={true} value={orgUser.email || ""} />
                </FormGroup>
                <FormGroup>
                  <Label>Phone:</Label>
                  <Input disabled={true} value={orgUser.phone || ""} />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const { modal, orgUsers, title } = this.props;
    if (!orgUsers) {
      return "";
    }
    return (
      <div>
        <Modal isOpen={modal} scrollable={true} toggle={this.handleOnClose}>
          <ModalHeader toggle={this.handleOnClose}>{title}</ModalHeader>
          <ModalBody>
            {orgUsers.map((orgUser, idx) => this.renderOrgUser(orgUser, idx))}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleOnClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
}
