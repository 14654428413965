import ErrorBoundary from "#Components/ErrorBoundary";
import { InfoColumn, InfoTable } from "#Components/InfoTable";
import InputForm, {
  SubmitButton,
  SwitchField,
  TextField,
} from "#Components/InputForm";
import Switch from "#Components/Switch";
import {
  submitAddAuthorizedUser,
  submitCreateUser,
  submitToggleAdminRole,
  submitToggleUserAuth,
} from "#Graphql/mutate";
import { checkEmailExists, fetchOrganizationUsers } from "#Graphql/query";
import { getUserIcon } from "#hoc/util";
import withRouter from "#hoc/withRouter";
import { AddOrgUserValidate } from "#pages/People/helpers";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import {
  Alert,
  Badge,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";

const resetState = {
  isLoading: false,
  showAddUserDialog: false,
  alreadyAdded: false,
  existingUserData: null,
  addUserAsAdmin: false,
};

class OrgUsers extends PureComponent {
  state = {
    ...resetState,
    orgUsers: [],
  };

  async componentDidMount() {
    const { location } = this.props;
    const action = location.searchParams.get("action");
    if (action && action === "add") {
      this.setState({ showAddUserDialog: true });
      window.history.replaceState("", document.title, "/people");
    }
    await this.loadOrgUsers();
  }

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState(resetState);
    this.props.alert.success(message);
  };

  loadOrgUsers = async () => {
    try {
      this.setState({ isLoading: true });
      const { orgUsersList } = await fetchOrganizationUsers();
      this.setState({
        orgUsers: orgUsersList,
        isLoading: false,
      });
    } catch (err) {
      this.errorHandler(err);
    }
  };

  toggleAddUserDialog = () => {
    this.setState({
      showAddUserDialog: !this.state.showAddUserDialog,
    });
  };

  handleOnClose = () => {
    this.setState(resetState);
    this.toggleAddUserDialog();
  };

  handleToggleEnabled = async (user) => {
    this.setState({ isLoading: true });
    try {
      const { toggleUserAuth } = await submitToggleUserAuth({
        userId: user.id,
        enabled: !user.enabled,
        deleted: false,
      });
      if (toggleUserAuth && toggleUserAuth.code === 200) {
        this.showSuccessMessage(toggleUserAuth.message);
        await this.loadOrgUsers();
      } else {
        this.errorHandler(toggleUserAuth);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  handleToggleAdminRole = async (user, authorizedUser, organizationRole) => {
    this.setState({ isLoading: true });
    const admin = organizationRole.role !== "client_admin";
    try {
      const { toggleAdminRole } = await submitToggleAdminRole({
        userId: user.id,
        admin,
        authorizedUser,
      });
      if (toggleAdminRole && toggleAdminRole.code === 200) {
        this.showSuccessMessage(toggleAdminRole.message);
        await this.loadOrgUsers();
      } else {
        this.errorHandler(toggleAdminRole);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  handleDeleteUser = async (user, authorizedUser) => {
    const action = authorizedUser ? "Remove" : "Delete";
    const confirmProps = {
      title: (
        <>
          <Badge color="danger">{action}</Badge> {user.firstName}{" "}
          {user.lastName}?
        </>
      ),
      message: (
        <p>
          Are you sure you want to <Badge color="danger">{action}</Badge>{" "}
          <strong>
            {user.firstName} {user.lastName}
          </strong>
          ?
        </p>
      ),
      confirmText: action,
      confirmColor: "danger",
      cancelText: "Cancel",
      cancelColor: "link",
    };
    const proceed = await confirm(confirmProps);
    if (!proceed) return;

    this.setState({ isLoading: true });
    try {
      const { toggleUserAuth } = await submitToggleUserAuth({
        userId: user.id,
        enabled: false,
        deleted: true,
        authorizedUser,
      });
      if (toggleUserAuth && toggleUserAuth.code === 200) {
        this.showSuccessMessage(toggleUserAuth.message);
        await this.loadOrgUsers();
      } else {
        this.errorHandler(toggleUserAuth);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  createUserSubmit = async ({ formFields: values }) => {
    const { existingUserData } = this.state;
    try {
      const { firstName, lastName, email, admin } = values;
      const userRole = admin ? "client_admin" : "client_user";
      this.setState({ isLoading: true });
      if (existingUserData) {
        const { addAuthorizedUser } = await submitAddAuthorizedUser({
          userId: existingUserData.id,
          role: userRole,
        });
        if (addAuthorizedUser && addAuthorizedUser.code === 201) {
          this.showSuccessMessage(addAuthorizedUser.message);
          await this.loadOrgUsers();
        } else {
          this.errorHandler(addAuthorizedUser);
        }
      } else {
        const { createUser } = await submitCreateUser({
          input: { firstName, lastName, email, userRole },
        });
        if (createUser && createUser.code === 201) {
          this.showSuccessMessage([
            createUser.message,
            "Account activation email sent successfully!",
          ]);
          await this.loadOrgUsers();
        } else {
          this.errorHandler(createUser);
        }
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  checkEmail = async (e) => {
    const { org } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const { checkEmail = {} } = await checkEmailExists(e.target.value);
    const { userData: existingUserData } = checkEmail;
    if (existingUserData) {
      const { authorizedOrgs = [] } = existingUserData;
      const alreadyAdded =
        existingUserData.orgId === org.id ||
        authorizedOrgs.map(({ id }) => id).includes(org.id);
      this.setState({
        alreadyAdded,
        existingUserData,
        addUserAsAdmin: existingUserData.admin,
      });
    } else {
      this.setState({
        alreadyAdded: false,
        existingUserData: null,
      });
    }
  };

  onAdminChange = (admin) => {
    this.setState({ addUserAsAdmin: admin });
  };

  renderAddUserDialog = () => {
    const {
      isLoading,
      showAddUserDialog,
      existingUserData,
      alreadyAdded,
      addUserAsAdmin,
    } = this.state;
    return (
      <Modal isOpen={showAddUserDialog} backdrop={true}>
        <ModalHeader toggle={this.handleOnClose}>Add User</ModalHeader>
        <InputForm
          validator={AddOrgUserValidate}
          validatorOptions={{ existingUserData, alreadyAdded }}
          onSubmit={this.createUserSubmit}
        >
          <ModalBody>
            <Row>
              <Col sm="12" className="mb-4">
                <Label>Email *</Label>
                <TextField
                  name="email"
                  type="email"
                  validateOnBlur
                  onBlur={this.checkEmail}
                />
              </Col>
              <Col sm="6" className="mb-4">
                <Label>First Name *</Label>
                {existingUserData ? (
                  <Input
                    name="firstName"
                    disabled
                    value={existingUserData.firstName}
                  />
                ) : (
                  <TextField name="firstName" />
                )}
              </Col>
              <Col sm="6" className="mb-4">
                <Label>Last Name *</Label>
                {existingUserData ? (
                  <Input
                    name="lastName"
                    disabled
                    value={existingUserData.lastName}
                  />
                ) : (
                  <TextField name="lastName" />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <SwitchField
                  name="admin"
                  label="Administrator"
                  checked={addUserAsAdmin}
                  onChange={this.onAdminChange}
                />
              </Col>
            </Row>
            {!alreadyAdded && existingUserData ? (
              <Row>
                <Col className="text-center mt-2">
                  <Alert color="warning" className="p-2 m-1">
                    This individual is affiliated with{" "}
                    <strong>{existingUserData.orgName}</strong> and will be
                    granted{" "}
                    <strong>{addUserAsAdmin ? "Administrator" : "User"}</strong>{" "}
                    access to your organization.
                  </Alert>
                </Col>
              </Row>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.handleOnClose}>
              Cancel
            </Button>
            <SubmitButton color="primary" disabled={alreadyAdded || isLoading}>
              Add
            </SubmitButton>
          </ModalFooter>
        </InputForm>
      </Modal>
    );
  };

  render() {
    const { org, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { orgUsers = [], isLoading } = this.state;

    const renderIcon = (user, index, column, id) => {
      if (user) {
        const isAuthorizedUser = org.id !== user.orgId;
        return user.photo ? (
          <img
            style={{ width: "30px", height: "30px" }}
            src={user.photo}
            alt=""
          />
        ) : (
          <i
            style={{ fontSize: "1.5rem" }}
            className={getUserIcon({
              ...user.organizationRole,
              isAuthorizedUser,
            })}
          />
        );
      }
    };

    const renderEnabled = (user, index, column, id) => {
      if (user) {
        const currentUser = user.userId === user.id;
        const authorizedUser = org.id !== user.orgId;
        if (currentUser || authorizedUser) {
          return "";
        } else {
          return (
            <Switch
              id={"enabled_" + user.id}
              checked={user.enabled}
              onChange={() => this.handleToggleEnabled(user)}
            />
          );
        }
      }
    };

    const renderAdminRole = (user, index, column, id) => {
      if (user) {
        const currentUser = user.userId === user.id;
        const authorizedUser = org.id !== user.orgId;
        const organizationRole = authorizedUser
          ? user.authorizedRole
          : user.organizationRole;
        if (currentUser) {
          return "";
        } else {
          return (
            <Switch
              id={"admin_" + user.id}
              checked={organizationRole?.role === "client_admin"}
              onChange={() =>
                this.handleToggleAdminRole(
                  user,
                  authorizedUser,
                  organizationRole,
                )
              }
            />
          );
        }
      }
    };

    const renderDelete = (user, index, column, id) => {
      if (user) {
        const currentUser = user.userId === user.id;
        const authorizedUser = org.id !== user.orgId;
        if (currentUser) {
          return "";
        } else {
          return (
            <Button
              outline
              color="danger"
              onClick={() => this.handleDeleteUser(user, authorizedUser)}
            >
              {authorizedUser ? (
                <i className="fa fa-user-minus" />
              ) : (
                <i className="fa fa-trash" />
              )}
            </Button>
          );
        }
      }
    };

    const renderOrgUsers = () => (
      <>
        <h4>Users & Authorized Users</h4>
        <h6 className="mt-3 mb-3">
          As the administrator of this organization, you have the authority to
          give other organization users access to your organization's deployed
          applications
        </h6>
        <InfoTable
          data={orgUsers}
          pagination={orgUsers.length > 10}
          highlightOnHover
          progressPending={isLoading}
          toolboxComponent={
            <div className="d-flex justify-content-end">
              <Button
                outline
                color="primary"
                size="sm"
                className="justify-content-end"
                onClick={this.toggleAddUserDialog}
              >
                <i className="fa fa-user-plus" /> Add User
              </Button>
            </div>
          }
        >
          <InfoColumn width="32px" cell={renderIcon} compact center />
          <InfoColumn
            selector={(user) => `${user.firstName} ${user.lastName}`}
            grow={2}
            sortable
          >
            Name
          </InfoColumn>
          <InfoColumn selector={(user) => user.email} grow={2} sortable>
            Email
          </InfoColumn>
          <InfoColumn cell={renderEnabled} center>
            Enabled
          </InfoColumn>
          <InfoColumn cell={renderAdminRole} center>
            Administrator
          </InfoColumn>
          <InfoColumn cell={renderDelete} center>
            Delete/Remove
          </InfoColumn>
        </InfoTable>
        {this.renderAddUserDialog()}
      </>
    );
    if (userRoleInOrg.isClientAdmin) {
      return <ErrorBoundary error="bg-error">{renderOrgUsers()}</ErrorBoundary>;
    }
    return null;
  }
}

export default withAlert()(withRouter(OrgUsers));
