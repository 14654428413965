import { gql } from "@apollo/client";

export const TOGGLEUSERAUTH = gql`
  mutation toggleUserAuth(
    $userId: Int!
    $enabled: Boolean!
    $deleted: Boolean!
    $authorizedUser: Boolean
  ) {
    toggleUserAuth(
      userId: $userId
      enabled: $enabled
      deleted: $deleted
      authorizedUser: $authorizedUser
    )
  }
`;
