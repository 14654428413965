import { useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getServiceHosts } from "#Components/DynamicForm/helpers";

const ExplainButton = ({
  serviceAttributes = {},
  extraDnsName,
  clusterDns,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const toggle = () => {
    setShowPopup(!showPopup);
  };
  const { dnsParts, dnsAlias } = getServiceHosts(
    extraDnsName,
    serviceAttributes,
  );
  const renderExplainButton =
    extraDnsName &&
    dnsParts.length > 2 &&
    dnsParts[0] &&
    dnsParts[dnsParts.length - 1];
  return (
    <div className="p-2">
      {renderExplainButton ? (
        <>
          {" "}
          <Alert id="explainBtn" color="warning" className="mt-0 mb-0 pb-0">
            <div>
              Make sure to configure <strong>CNAME</strong> records for the
              above specified host(s) to point to <strong>{clusterDns}</strong>{" "}
              within your DNS service provider.
            </div>
            <div className="text-end mt-3">
              <Button color="link" size="sm" onClick={toggle}>
                Explain How?
              </Button>
            </div>
          </Alert>
          <Modal
            placement="bottom"
            isOpen={showPopup}
            target="explainBtn"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>
              How to add a CNAME Record?
            </ModalHeader>
            <ModalBody>
              <p>
                To add a CNAME record to your DNS service provider, you will
                need to follow a process that can vary slightly depending on the
                specific DNS provider you're using. Here is a general outline of
                the steps involved:
              </p>

              <ol>
                <li>
                  <p>
                    <strong>Log in to your DNS provider:</strong> Access your
                    DNS provider's website and log in to your account. This
                    would be the place where you have your domain registered,
                    like GoDaddy, Bluehost, Cloudflare, or Namecheap.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Access DNS Management:</strong> Look for the DNS
                    settings or DNS management page. This is often found within
                    the domain management or advanced settings area.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Add a new DNS record:</strong> Look for an option to
                    "Add Record", "Create Record", or similar. Click on it.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Specify the CNAME record:</strong> You'll need to
                    fill in at least three fields:
                  </p>
                  <ul className="mb-3">
                    <li>
                      <strong>Type:</strong> This should be set to "CNAME".
                    </li>
                    <li>
                      <strong>Host or Alias:</strong> Here, you'd enter `
                      <strong>{dnsAlias}</strong>`. Some providers might require
                      you to put the full domain `
                      <strong>{extraDnsName}</strong>`.
                    </li>
                    <li>
                      <strong>Value, Points to, or Target:</strong> Here, you'd
                      enter `<strong>{clusterDns}</strong>`.
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <strong>Save the record:</strong> After you've filled in the
                    necessary information, look for a button to save or create
                    the record.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Propagation time:</strong> After you've added the
                    CNAME record, it may take up to 48 hours for the changes to
                    propagate across the internet, although it usually happens
                    much faster.
                  </p>
                </li>
              </ol>

              <p>
                Remember, before you make changes to your DNS settings, it's a
                good idea to write down the current settings, just in case you
                need to revert back.
              </p>

              <p>
                This is a general guide and the steps might differ slightly
                depending on your DNS provider. If you have any trouble, it's a
                good idea to consult the specific help documentation or support
                services of your DNS provider.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : null}
    </div>
  );
};

export default ({ formContext }) => {
  const { ticketDetails = {}, formData = {} } = formContext;
  const { attributes = {} } = ticketDetails;
  const { cluster = {} } = formData;
  const {
    cloudServiceProvider: csp = "",
    name = "",
    nameserver = "",
    extraDnsName = "",
    extraDnsApps = [],
  } = cluster;
  return (
    <ExplainButton
      serviceAttributes={attributes}
      extraDnsName={extraDnsName}
      extraDnsApps={extraDnsApps}
      clusterDns={`${csp.toLowerCase()}-${name}.${nameserver}`}
    />
  );
};
