import { gql } from "@apollo/client";

export const SERVICECATALOGTEMP = gql`
  query serviceCatalogTemplates {
    serviceCatalogTemplates {
      id
      service
      serviceType
      configParams
    }
  }
`;
