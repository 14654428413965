import TabNav from "#Components/TabNav";
import { getUserIcon } from "#hoc/util";
import MyAgent from "#pages/Profile/MyAgent";
import MyOrganization from "#pages/Profile/MyOrganizations";
import MyProfile from "#pages/Profile/MyProfile";
import PaymentDetails from "#pages/Profile/PaymentDetails";
import React, { PureComponent } from "react";
import { Card } from "reactstrap";

class ProfilePage extends PureComponent {
  isVisible = (tabId) => {
    const { user, org, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );

    if (tabId === "/myprofile") {
      return true;
    } else if (tabId === "/myorg") {
      return userRoleInOrg.isClient;
    } else if (tabId === "/myagent") {
      return userRoleInOrg.isClient;
    } else if (tabId === "/payment") {
      return userRoleInOrg.isClient && !org.exempt;
    }
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    return (
      <Card className="content-wrapper">
        <TabNav
          tabs={[
            {
              tabId: "/myprofile",
              tabIcon: getUserIcon(userRoleInOrg),
              tabLabel: "My Profile",
              tabComponent: <MyProfile />,
            },
            {
              tabId: "/myorg",
              tabIcon: "fa fa-building",
              tabLabel: "My Organization(s)",
              tabComponent: <MyOrganization />,
            },
            {
              tabId: "/myagent",
              tabIcon: getUserIcon({ isAgent: true }),
              tabLabel: "My Agent",
              tabComponent: <MyAgent />,
            },
            {
              tabId: "/payment",
              tabIcon: "fa fa-credit-card",
              tabLabel: "Payment Method",
              tabComponent: <PaymentDetails />,
            },
          ]}
          isVisible={this.isVisible}
          width="medium"
        />
      </Card>
    );
  }
}

export default ProfilePage;
