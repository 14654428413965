import React from "react";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Alert } from "reactstrap";
import Account from "./pages/Account";
import App from "./pages/App";

import "./styles/index.scss";

const colorMap = {
  info: "secondary",
  success: "primary",
  error: "danger",
};

const alertOptions = {
  timeout: 30000, // 30 seconds
  position: positions.BOTTOM_LEFT,
  transition: transitions.FADE,
  containerStyle: {
    marginBottom: "40px",
    justifyContent: "flex-end",
    flexDirection: "column-reverse",
    maxHeight: "25vh",
    overflow: "auto",
    zIndex: 1030,
  },
};

const alertStyle = {
  maxHeight: "25vh",
  width: "25vw",
  minWidth: "300px",
  overflow: "auto",
};

const AlertTemplate = ({ message, options, style, close }) => {
  if (typeof message === "string") {
    message = [message];
  }
  message.forEach((msg, idx) => {
    if (msg.match(/^graphQL error:/gi)) {
      message[idx] = message[idx].replace(/^graphQL error:/gi, "");
    }
  });

  return (
    <Alert
      style={{ ...style, ...alertStyle }}
      color={colorMap[options.type] || "secondary"}
      toggle={close}
    >
      {message.map((msg, idx) => (
        <div key={idx}>
          <div>{msg}</div>
          {idx < message.length - 1 && <hr />}
        </div>
      ))}
    </Alert>
  );
};

const Main = () => {
  return (
    <section className="main-container">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Account />} />
          <Route path="/signup" element={<Account />}>
            <Route path="success" element={<Account />} />
          </Route>
          <Route path="/forgot-password" element={<Account />} />
          <Route path="/reset-password" element={<Account />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </section>
  );
};

createRoot(document.getElementById("root")).render(
  <AlertProvider template={AlertTemplate} {...alertOptions}>
    <Main />
    <div className="copyright">
      <div>
        Clouve © {new Date().getFullYear()}&nbsp;&bull;&nbsp;All rights
        reserved
        <br />
        <a href="https://www.clouve.com/privacy/" target="_blank">
          Terms of Service
        </a>
        &nbsp;&bull;&nbsp;
        <a href="https://www.clouve.com/terms/" target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
  </AlertProvider>,
);
