import { client } from "#hoc/config";
import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Navigate } from "react-router-dom";

export const verifyToken = () => {
  try {
    const clvObj = JSON.parse(sessionStorage.getItem("Clouve.object") || null);
    if (clvObj) {
      const current_time = Date.now().valueOf() / 1000;
      const expires_time = Date.parse(clvObj.expires).valueOf() / 1000;
      if (current_time < expires_time) {
        return clvObj;
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export const withAuth = (Component) => (props) => {
  if (verifyToken()) {
    return (
      <ApolloProvider client={client}>
        <Component {...props} />
      </ApolloProvider>
    );
  } else {
    const { pathname, hash } = window.location;
    if (!pathname.match(/^\/login/gi)) {
      localStorage.setItem("afterLoginURI", pathname + hash);
    }
    return <Navigate to="/login" />;
  }
};

export const withNoAuth = (Component) => (props) => {
  return verifyToken() ? (
    <Navigate to="/" />
  ) : (
    <ApolloProvider client={client}>
      <Component {...props} />
    </ApolloProvider>
  );
};
