import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({ uri: "/thermo/graphql" });

const logoutLink = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.statusCode === 401 &&
    networkError.result.error === "NO_SESSION_FOUND"
  ) {
    sessionStorage.clear();
    window.location = "/login";
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink.concat(httpLink),
});
