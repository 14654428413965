import ErrorBoundary from "#Components/ErrorBoundary";
import Checkout from "#pages/Checkout";
import Clients from "#pages/Clients";
import Clusters from "#pages/Clusters";
import CreateDeployment from "#pages/CreateDeployment";
import CreateSupport from "#pages/CreateSupport";
import Deployments from "#pages/Deployments";
import GetHelp from "#pages/GetHelp";
import History from "#pages/History";
import People from "#pages/People";
import Profile from "#pages/Profile";
import Tickets from "#pages/Tickets";
import TicketDetails from "#pages/Tickets/TicketDetails";

import React from "react";
import { Route, Routes } from "react-router-dom";

const Content = (props) => {
  const { userRoleInOrg } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  const landingPage =
    userRoleInOrg.isSuperAdmin || userRoleInOrg.isSalesAgent ? (
      <Clients />
    ) : userRoleInOrg.isAgent ? (
      <Tickets />
    ) : (
      <Deployments />
    );
  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/deployments" element={<Deployments />} />
        <Route path="/deployment" element={<TicketDetails />} />
        <Route path="/deployment/create" element={<CreateDeployment />} />
        <Route
          path="/deployment/create/:serviceName"
          element={<CreateDeployment />}
        />
        <Route path="/deployment/:orgTicketId" element={<TicketDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route
          path="/checkout/success/:orgTicketId"
          element={<TicketDetails />}
        />
        <Route path="/support" element={<TicketDetails />} />
        <Route path="/support/create" element={<CreateSupport />} />
        <Route path="/support/:orgTicketId" element={<TicketDetails />} />
        <Route path="/tickets" element={<Tickets />}>
          <Route path=":ticketType" element={<Tickets />}>
            <Route path=":ticketState" element={<Tickets />}>
              <Route path=":orgId" element={<Tickets />} />
            </Route>
          </Route>
        </Route>
        <Route path="/people" element={<People />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/history" element={<History />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/clusters" element={<Clusters />} />
        <Route path="/help" element={<GetHelp />} />
        <Route path="*" element={landingPage} />
      </Routes>
    </ErrorBoundary>
  );
};

export default Content;
