import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const Switch = (props) => {
  const {
    id,
    name,
    label,
    checked,
    disabled,
    invalid,
    onChange = () => {},
  } = props;
  const handleOnChange = (evt) => {
    if (evt?.target?.tagName === "INPUT") {
      !disabled && onChange(evt.target.checked);
    } else {
      !disabled && onChange(!checked);
    }
  };

  return (
    <FormGroup
      switch
      disabled={disabled}
      className={invalid ? "toggle-switch is-invalid" : "toggle-switch"}
    >
      <Input
        id={id}
        name={name}
        type="switch"
        role="switch"
        disabled={disabled}
        checked={checked}
        onChange={handleOnChange}
      />
      {label ? (
        <Label check onClick={handleOnChange}>
          {label}
        </Label>
      ) : null}
    </FormGroup>
  );
};

export default Switch;
