export const getServiceSchema = "/thermo/getSchema";

// deployment actions
export const agentLaunchAction = "/thermo/action/agent/launch";
export const agentUpdateAction = "/thermo/action/agent/update";
export const agentUpgradeAction = "/thermo/action/agent/upgrade";
export const agentCloneAction = "/thermo/action/agent/clone";
export const agentPauseAction = "/thermo/action/agent/pause";
export const agentResumeAction = "/thermo/action/agent/resume";
export const agentStatusAction = "/thermo/action/agent/status";
export const agentDeleteAction = "/thermo/action/agent/delete";
export const agentTokenAction = "/thermo/action/agent/token";
export const agentBackupAction = "/thermo/action/agent/backup";
export const agentBackupApplyAction = "/thermo/action/agent/backup_apply";

export const cloneAction = "/thermo/action/clone";
export const upgradeAction = "/thermo/action/upgrade";
export const deleteAction = "/thermo/action/delete";
export const pauseAction = "/thermo/action/pause";
export const resumeAction = "/thermo/action/resume";
export const backupAction = "/thermo/action/backup";
export const backupApplyAction = "/thermo/action/backup_apply";
export const cancelTrialAction = "/thermo/action/cancel_trial";
export const cancelSubscriptionAction = "/thermo/action/cancel_subscription";
export const updateSubscriptionAction = "/thermo/action/update_subscription";

// deployment configuration actions
export const UpActions = [
  "launch",
  "clone",
  "upgrade",
  "update",
  "resume",
  "backup",
  "backup_apply",
  "status",
  "agent/token",
  "agent/clone",
];
