import { checkCnameMapping, checkDNSRecord } from "#Graphql/query";
import _ from "lodash";

const CLV_DEFAULT_NAMESERVER =
  window.VITE_CLV_DEFAULT_NAMESERVER || "clouve.app";

const dnsNameRE = /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/gi;
const dnsNameXRE = /-{2,}/gi;
const extraDnsNameRE =
  /^(?!-)[A-Za-z0-9-]+([\-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/gi;

export const isValidDnsName = (dnsName) => {
  return dnsName.match(dnsNameRE) && !dnsName.match(dnsNameXRE);
};
export const isValidExtraDnsName = (extraDnsName) => {
  return (
    extraDnsName.match(extraDnsNameRE) && extraDnsName.split(".").length >= 2
  );
};

export const getServiceHosts = (extraDnsName = "", serviceAttributes = {}) => {
  const dnsParts = extraDnsName.split(".");
  const nakedDomain = dnsParts.length <= 2;
  nakedDomain && dnsParts.unshift("www");
  const dnsAlias = dnsParts.slice(0, -2).join("."); //remove naked domain dns part
  const {
    serviceTitle = "",
    suiteApps = {},
    suiteTitles = {},
  } = serviceAttributes;
  const serviceHosts = [
    {
      appId: "default",
      appTitle: suiteTitles["default"] || serviceTitle,
      host: dnsParts.join("."),
    },
  ];
  for (let key in suiteApps) {
    if (key !== "default") {
      serviceHosts.push({
        appId: key,
        appTitle: suiteTitles[key],
        host: `${key}.${extraDnsName}`,
      });
    }
  }
  return { dnsParts, dnsAlias, serviceHosts };
};

export const validateClusterFields = async ({
  persistedFormData = {},
  formData = {},
  servicePlans = {},
}) => {
  const errors = {};
  const { cluster: pCluster = {} } = persistedFormData;
  const {
    dnsName: pDnsName,
    extraDnsName: pExtraDnsName,
    extraDnsApps: pExtraDnsApps = [],
  } = pCluster;
  const { cluster = {} } = formData;
  const {
    capacity = "x-small",
    dnsName,
    extraDnsName,
    extraDnsApps = [],
  } = cluster;
  if (capacity) {
    const capacityErrors = [];
    if (!servicePlans[capacity]) {
      capacityErrors.push(
        `This app does not currently support ${capacity} capacity`,
      );
    }
    if (capacityErrors.length) {
      _.set(errors, "cluster.capacity.__errors", capacityErrors);
    }
  }
  if (dnsName && dnsName !== pDnsName) {
    if (isValidDnsName(dnsName)) {
      try {
        await checkDNSRecord(cluster);
      } catch (err) {
        _.set(errors, "cluster.dnsName.__errors", err.message.split("<|>"));
      }
    } else {
      _.set(errors, "cluster.dnsName.__errors", ["Invalid domain name"]);
    }
  }
  if (
    extraDnsName &&
    (extraDnsName !== pExtraDnsName || !_.isEqual(extraDnsApps, pExtraDnsApps))
  ) {
    let allValid = true;
    if (!isValidExtraDnsName(extraDnsName)) {
      allValid = false;
      _.set(errors, "cluster.extraDnsName.__errors", ["Invalid domain name"]);
    }
    const hosts = [extraDnsName];
    extraDnsApps.forEach(({ appId, appTitle, host }, idx) => {
      hosts.push(host);
      if (!isValidExtraDnsName(host)) {
        allValid = false;
        if (appId !== "default") {
          _.set(errors, `cluster.extraDnsApps[${idx}].__errors`, [
            "Invalid domain name",
          ]);
        }
      }
    });
    if (allValid) {
      try {
        await checkCnameMapping([...new Set(hosts)], {
          nameserver: CLV_DEFAULT_NAMESERVER,
          ...cluster,
        });
      } catch (err) {
        _.set(
          errors,
          "cluster.explainButton.__errors",
          err.message.split("<|>"),
        );
      }
    }
  }
  return errors;
};
