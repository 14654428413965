import TabNav from "#Components/TabNav";
import withRouter from "#hoc/withRouter";
import React, { useEffect } from "react";
import { Card } from "reactstrap";
import ClouveAgents from "./ClouveAgents";

import OrgUsers from "./OrgUsers";

const People = (props) => {
  const { user, userRoleInOrg } = JSON.parse(
    sessionStorage.getItem("Clouve.object"),
  );
  const defaultTab =
    userRoleInOrg.isSuperAdmin || userRoleInOrg.isAgentAdmin
      ? "/fta"
      : userRoleInOrg.isSalesAgent
        ? "/sales"
        : "/client";

  useEffect(() => {
    if (userRoleInOrg.isClientUser) {
      props.history.push("/");
    }
  }, []);

  const isVisible = (tabId) => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );

    if (tabId === "/client") {
      return userRoleInOrg.isClientAdmin;
    } else if (tabId === "/fta") {
      return userRoleInOrg.isSuperAdmin || userRoleInOrg.isAgentAdmin;
    } else if (tabId === "/sta") {
      return userRoleInOrg.isSuperAdmin || userRoleInOrg.isAgentAdmin;
    } else if (tabId === "/sales") {
      return userRoleInOrg.isSuperAdmin;
    } else if (tabId === "/admin") {
      return userRoleInOrg.isSuperAdmin;
    }
  };

  return (
    <Card className="content-wrapper">
      <TabNav
        tabs={[
          {
            tabId: "/client",
            tabLabel: "Authorized Users",
            tabComponent: <OrgUsers />,
          },
          {
            tabId: "/fta",
            tabLabel: "First Tier",
            tabIcon: "fa fa-users-line",
            tabComponent: (
              <ClouveAgents agentRole="agent_admin" label="First Tier Agents" />
            ),
          },
          {
            tabId: "/sta",
            tabLabel: "Second Tier",
            tabIcon: "fa fa-users-between-lines",
            tabComponent: (
              <ClouveAgents agentRole="agent" label="Second Tier Agents" />
            ),
          },
          {
            tabId: "/sales",
            tabLabel: "Sales",
            tabIcon: "fa fa-users-rays",
            tabComponent: <ClouveAgents agentRole="sales_agent" />,
          },
          {
            tabId: "/admin",
            tabLabel: "Admin",
            tabIcon: "fa fa-users-gear",
            tabComponent: <ClouveAgents agentRole="super_admin" />,
          },
        ]}
        defaultTab={defaultTab}
        isVisible={isVisible}
      />
    </Card>
  );
};

export default withRouter(People);
