import { gql } from "@apollo/client";

export const UPDATEMYPROFILE = gql`
  mutation updateMyProfile($input: UpdateUserInput!) {
    updateMyProfile(input: $input)
  }
`;

export const RESENDVERIFICATIONEMAIL = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail
  }
`;
