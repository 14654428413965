import ErrorBoundary from "#Components/ErrorBoundary";
import { InfoColumn, InfoTable } from "#Components/InfoTable";
import InputForm, { SubmitButton, TextField } from "#Components/InputForm";
import Switch from "#Components/Switch";
import { submitCreateUser, submitToggleUserAuth } from "#Graphql/mutate";
import { fetchClouveAgentsList } from "#Graphql/query";
import { getUserIcon } from "#hoc/util";
import { AddAgentValidate } from "#pages/People/helpers";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import {
  Badge,
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";

class ClouveAgents extends PureComponent {
  state = {
    isLoading: false,
    showAddAgentDialog: false,
    checkVerifiedEmail: false,
    agentList: [],
  };

  async componentDidMount() {
    await this.loadAgents();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { agentRole } = this.props;
    if (agentRole !== prevProps.agentRole) {
      await this.loadAgents();
    }
  }

  loadAgents = async () => {
    const { agentRole } = this.props;
    this.setState({ isLoading: true });
    try {
      const { clouveAgentsList: agentList } =
        await fetchClouveAgentsList(agentRole);
      this.setState({
        agentList,
        isLoading: false,
      });
    } catch (err) {
      this.errorHandler(err);
    }
  };

  toggleAddAgentDialog = () => {
    this.setState({
      showAddAgentDialog: !this.state.showAddAgentDialog,
    });
  };

  handleOnClose = () => {
    this.toggleAddAgentDialog();
    this.setState({
      checkVerifiedEmail: false,
    });
  };

  handleToggleEnabled = async (user) => {
    this.setState({ isLoading: true });
    try {
      const { toggleUserAuth } = await submitToggleUserAuth({
        userId: user.id,
        enabled: !user.enabled,
        deleted: false,
      });
      if (toggleUserAuth && toggleUserAuth.code === 200) {
        this.showSuccessMessage(toggleUserAuth.message);
        await this.loadAgents();
      } else {
        this.errorHandler(toggleUserAuth);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  handleDeleteAgent = async (user) => {
    const confirmProps = {
      title: (
        <>
          <Badge color="danger">Delete</Badge> {user.firstName} {user.lastName}?
        </>
      ),
      message: (
        <p>
          Are you sure you want to <Badge color="danger">Delete</Badge>{" "}
          <strong>
            {user.firstName} {user.lastName}
          </strong>
          ?
        </p>
      ),
      confirmText: "Delete",
      confirmColor: "danger",
      cancelText: "Cancel",
      cancelColor: "link",
    };
    const proceed = await confirm(confirmProps);
    if (!proceed) return;

    this.setState({ isLoading: true });
    try {
      const { toggleUserAuth } = await submitToggleUserAuth({
        userId: user.id,
        enabled: false,
        deleted: true,
      });
      if (toggleUserAuth && toggleUserAuth.code === 200) {
        this.showSuccessMessage(toggleUserAuth.message);
        await this.loadAgents();
      } else {
        this.errorHandler(toggleUserAuth);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false, showAddAgentDialog: false });
    this.props.alert.success(message);
  };

  createUserSubmit = async ({ formFields: values }) => {
    const { agentRole } = this.props;
    this.setState({ isLoading: true });
    try {
      const { firstName, lastName, email } = values;
      const { createUser } = await submitCreateUser({
        input: { firstName, lastName, email, agentRole },
      });
      if (createUser && createUser.code === 201) {
        this.showSuccessMessage([
          createUser.message,
          "Account activation email sent successfully!",
        ]);
        await this.loadAgents();
      } else {
        this.errorHandler(createUser);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { label } = this.props;
    const {
      agentList = [],
      checkVerifiedEmail,
      isLoading,
      showAddAgentDialog,
    } = this.state;

    const renderIcon = (user, index, column, id) => {
      if (user) {
        return (
          <>
            {user.photo ? (
              <img
                className="rounded-circle profile-image-btn"
                title={`${user.firstName} ${user.lastName}`}
                alt={`${user.firstName} ${user.lastName}`}
                style={{ width: "30px", height: "30px" }}
                src={user.photo}
              />
            ) : (
              <i
                style={{ fontSize: "1.5rem" }}
                className={getUserIcon(user.organizationRole)}
              />
            )}
          </>
        );
      }
    };

    const renderEnabled = (user, index, column, id) => {
      if (user.userId === user.id) {
        return "";
      } else {
        return (
          <Switch
            id={"enabled_" + user.id}
            label=""
            checked={user.enabled}
            onChange={() => this.handleToggleEnabled(user)}
          />
        );
      }
    };

    const renderDelete = (user, index, column, id) => {
      if (user.userId === user.id) {
        return "";
      } else {
        return (
          <Button
            outline
            color="danger"
            onClick={() => this.handleDeleteAgent(user)}
          >
            <i className="fa fa-trash" />
          </Button>
        );
      }
    };

    const renderAgentsList = () => (
      <>
        <h4>{label}</h4>
        <h6 className="mt-3 mb-3">
          As the administrator of this organization, you have the authority to
          add, enable, disable, and delete agents.
        </h6>
        <Modal isOpen={showAddAgentDialog} backdrop={true}>
          <ModalHeader toggle={this.handleOnClose}>Add Agent</ModalHeader>
          <InputForm
            validator={AddAgentValidate}
            onSubmit={this.createUserSubmit}
          >
            <ModalBody className="p-5">
              <Row>
                <Col sm="12" className="mb-4">
                  <Label>Email *</Label>
                  <TextField name="email" validateOnBlur />
                </Col>
                <Col sm="6" className="mb-4">
                  <Label>First Name *</Label>
                  <TextField name="firstName" />
                </Col>
                <Col sm="6" className="mb-4">
                  <Label>Last Name *</Label>
                  <TextField name="lastName" />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={this.handleOnClose}>
                Cancel
              </Button>
              <SubmitButton color="primary" disabled={checkVerifiedEmail}>
                Add
              </SubmitButton>
            </ModalFooter>
          </InputForm>
        </Modal>
        <InfoTable
          data={agentList}
          pagination={agentList.length > 10}
          highlightOnHover
          progressPending={isLoading}
          toolboxComponent={
            <div className="d-flex justify-content-end">
              <Button
                outline
                color="primary"
                size="sm"
                onClick={this.toggleAddAgentDialog}
              >
                <span className="fa-stack fa-1x">
                  <i
                    className="fa fa-user-tie fa-stack-2x"
                    data-fa-transform="left-6"
                  />
                  <i
                    className="fa-solid fa-plus fa-stack-1x"
                    data-fa-transform="right-4 up-2"
                  />
                </span>{" "}
                Add Agent
              </Button>
            </div>
          }
        >
          <InfoColumn width="32px" cell={renderIcon} compact center />
          <InfoColumn
            selector={(user) => `${user.firstName} ${user.lastName}`}
            grow={2}
            sortable
          >
            Name
          </InfoColumn>
          <InfoColumn selector={(user) => user.email} grow={2} sortable>
            Email
          </InfoColumn>
          <InfoColumn cell={renderEnabled} center>
            Enabled
          </InfoColumn>
          <InfoColumn cell={renderDelete} center>
            Delete
          </InfoColumn>
        </InfoTable>
      </>
    );
    return <ErrorBoundary error="bg-error">{renderAgentsList()}</ErrorBoundary>;
  }
}

export default withAlert()(ClouveAgents);
