import { submitAddNewPaymentCard } from "#Graphql/mutate";
import { fetchClientPaymentMethod } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import DropIn from "braintree-web-drop-in-react";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Alert, Spinner } from "reactstrap";

class PaymentDetails extends PureComponent {
  state = {
    isLoading: false,
    dropInInstance: null,
    clientPaymentToken: null,
  };

  async componentDidMount() {
    await this.getClientPaymentMethod();
  }

  getClientPaymentMethod = async () => {
    try {
      this.setState({ isLoading: true });
      const { getClientPaymentMethod } = await fetchClientPaymentMethod();
      this.setState({ loading: false });
      if (getClientPaymentMethod) {
        this.setState({
          clientPaymentToken: getClientPaymentMethod,
        });
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  addPaymentCard = async () => {
    const { dropInInstance } = this.state;
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { nonce } = await dropInInstance.requestPaymentMethod();
    const submitButton = document.querySelector("#submitButton");
    submitButton.setAttribute("disabled", true);

    try {
      const { addNewPaymentCard } = await submitAddNewPaymentCard({
        userId: user.userId,
        paymentMethodNonce: nonce,
      });
      if (addNewPaymentCard && addNewPaymentCard.code === 200) {
        this.showSuccessMessage(addNewPaymentCard.message);
        this.getClientPaymentMethod();
        this.forceUpdate();
      } else {
        this.errorHandler(response);
      }
    } catch (response) {
      this.errorHandler(response);
      this.setState({
        loading: false,
      });
    }
  };

  noPaymentMethodRequestable = (event) => {
    const submitButton = document.querySelector("#submitButton");
    submitButton.setAttribute("disabled", true);
  };
  onPaymentMethodRequestable = (event) => {
    const submitButton = document.querySelector("#submitButton");
    submitButton.removeAttribute("disabled", true);
    if (event.paymentMethodIsSelected) {
      submitButton.setAttribute("disabled", true);
    }
  };

  onPaymentOptionSelected = (event) => {
    const submitButton = document.querySelector("#submitButton");
    submitButton.setAttribute("disabled", true);
  };

  brainTreeDropInInstance = (dropInInstance) => {
    this.setState({ dropInInstance }, () => {
      const submitButton = document.querySelector("#submitButton");
      submitButton.setAttribute("disabled", true);
    });
  };

  render() {
    const { clientPaymentToken, loading, dropInInstance } = this.state;
    const options = {
      authorization: clientPaymentToken,
      paymentOptionPriority: [
        "card",
        "paypal",
        "paypalCredit",
        // "venmo",
        // "applePay",
        // "googlePay",
      ],
      paypal: { flow: "vault", amount: "", currency: "USD" },
      paypalCredit: { flow: "vault", amount: "", currency: "USD" },
      vaultManager: true,
      defaultFirst: false,
      cardPaymentMethodPayload: { details: "cardType" },
      preselectVaultedPaymentMethod: true,
    };

    if (loading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (clientPaymentToken) {
      return (
        <>
          <Alert color="warning">
            <i className="fa fa-warning" /> Deleting a payment method may cancel
            your active subscriptions!
          </Alert>
          <DropIn
            options={options}
            onPaymentMethodRequestable={this.onPaymentMethodRequestable}
            onNoPaymentMethodRequestable={this.noPaymentMethodRequestable}
            onPaymentOptionSelected={this.onPaymentOptionSelected}
            onInstance={this.brainTreeDropInInstance}
          />
          {dropInInstance && (
            <div className="text-center">
              <button
                id="submitButton"
                onClick={this.addPaymentCard}
                className="mt-4 mb-4 btn btn-primary btn-md"
              >
                Save
              </button>
            </div>
          )}
        </>
      );
    } else {
      return (
        <div className="mt-4 mb-4 p-3">
          <div className="loading-spinner">
            <Spinner type="grow" />
          </div>
        </div>
      );
    }
  }
}

export default withAlert()(withRouter(PaymentDetails));
