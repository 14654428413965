import FileUploader from "#Components/FileUploader";
import React from "react";
import { withAlert } from "react-alert";
import { Col } from "reactstrap";

const styles = {
  fileUploadForm: {
    position: "absolute",
    opacity: "0",
    top: "0px",
    width: "96%",
    height: "80px",
    zIndex: "999",
    cursor: "pointer",
  },
};

class LogoUploader extends FileUploader {
  renderAttachmentPreview = () => {
    let { uploadedFiles } = this.state;

    if (uploadedFiles) {
      return (
        <div className="file-preview">
          <div
            className="attached-img"
            style={{ backgroundImage: `url(${uploadedFiles[0].fileUrl})` }}
            title={uploadedFiles[0].fileName}
          />
        </div>
      );
    }
  };

  renderUploader = () => {
    const { uploadedFiles } = this.state;
    if (!uploadedFiles.length) {
      return <div className="fileprev-uploader" />;
    } else {
      return <div className="fileprev"> {this.renderAttachmentPreview()} </div>;
    }
  };

  render = () => {
    return (
      <div className="logo-uploader">
        <Col className="file-attachment-cm">
          <div className="file-upload file-upload-profile">
            <div className="drop-box">{this.renderUploader()}</div>
            <div className="changed-Img-text">Change</div>

            <input
              style={styles.fileUploadForm}
              type="file"
              name="attachments"
              onChange={this.handleFileUpload}
              id="uploadImage"
              accept="image/*, png, jpg, jpeg"
            />
          </div>
        </Col>
      </div>
    );
  };
}

export default withAlert()(LogoUploader);
