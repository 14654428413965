import React, { PureComponent } from "react";
import { Button, Input, Label } from "reactstrap";

export default class ViewCompanyDetails extends PureComponent {
  render() {
    const { org, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    return (
      <div className="company-info">
        <div className="logo">
          {org.logo ? (
            <div
              className="profile-image"
              style={{
                backgroundImage: `url(${org.logo})`,
              }}
              title={org.name}
            />
          ) : (
            <div className="profile-image">
              <i
                title={org.name}
                style={{ fontSize: "3.5rem" }}
                className={
                  userRoleInOrg.isAuthorizedUser
                    ? "fa-regular fa-building"
                    : "fa-solid fa-building"
                }
              />
            </div>
          )}
        </div>
        <div className="row-format">
          <Label>Name</Label>
          <Input defaultValue={org.name} disabled />
        </div>
        <div className="row-format">
          <Label>Email</Label>
          <Input defaultValue={org.email} disabled />
        </div>
        <div className="row-format">
          <Label>Website</Label>
          <Input defaultValue={org.website} disabled />
        </div>
        <div className="row-format">
          <Label>Phone</Label>
          <Input defaultValue={org.phone} disabled />
        </div>
        <div className="row-format">
          <Label>Fax</Label>
          <Input defaultValue={org.fax} disabled />
        </div>
        <div className="row-format">
          <Label>Address</Label>
          <Input defaultValue={org.address} disabled />
        </div>
        {userRoleInOrg.isClientAdmin ? (
          <div className="text-end">
            <Button
              onClick={() => this.props.onOpen()}
              size="sm"
              color="link"
              className="font-12"
            >
              <i className="fa fa-edit" /> Edit
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
