import { gql } from "@apollo/client";

export const GETTICKETFIELDS = gql`
  query ticketFields {
    ticketTypes
    states
    urgencies
    getDeploymentsList
    selfServiceTypes {
      serviceName
      serviceAttributes
      servicePlans
    }
    getOrgTicketId
  }
`;

export const DNSRECORDEXISTS = gql`
  query dnsRecordExists($cluster: JSON!) {
    dnsRecordExists(cluster: $cluster)
  }
`;

export const CHECKCNAMEMAPPING = gql`
  query checkCnameMapping($requestedDomains: [String]!, $cluster: JSON!) {
    checkCnameMapping(requestedDomains: $requestedDomains, cluster: $cluster)
  }
`;
