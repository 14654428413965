import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

export const VideoPreview = ({ title, linkId, description }) => {
  const [showPreview, setShowPreview] = useState(false);
  const closeImagePreview = () => {
    setShowPreview(false);
  };
  const openImagePreview = () => {
    setShowPreview(true);
  };
  return (
    <>
      <Card>
        <iframe
          width="100%"
          height="400"
          src={`https://www.youtube-nocookie.com/embed/${linkId}?controls=0&rel=0&playsinline=1&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fwww.clouve.com&widgetid=1`}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
        <CardBody>
          <CardSubtitle
            className="mb-2 inline-link"
            tag="h6"
            onClick={openImagePreview}
          >
            {title}
          </CardSubtitle>
          <CardText>{description}</CardText>
        </CardBody>
      </Card>
      <Modal
        size="lg"
        isOpen={showPreview}
        toggle={closeImagePreview}
        fade={false}
      >
        <ModalHeader toggle={closeImagePreview}>{title}</ModalHeader>
        <ModalBody className="text-center">
          <iframe
            title={title}
            src={`https://www.youtube-nocookie.com/embed/${linkId}?controls=0&rel=0&playsinline=1&modestbranding=0&autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fwww.clouve.com&widgetid=1`}
            className="video-preview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </ModalBody>
        <ModalFooter>
          <Button color="link" size="sm" onClick={closeImagePreview}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
