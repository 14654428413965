import { gql } from "@apollo/client";

export const ATTACHMENT = gql`
  mutation uploadAttachment(
    $originalName: String
    $fileType: String
    $fileSize: Int
    $file: JSON
    $ticketId: Int
  ) {
    uploadAttachment(
      originalName: $originalName
      fileType: $fileType
      file: $file
      fileSize: $fileSize
      ticketId: $ticketId
    )
  }
`;
