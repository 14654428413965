import { getUserIcon } from "#hoc/util";
import React, { PureComponent } from "react";
import { Label } from "reactstrap";

class MyAgent extends PureComponent {
  state = {};

  render() {
    const { fta = {} } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    return (
      <div className="agent-info">
        <div className="logo">
          {fta.photo ? (
            <div
              className="profile-image"
              style={{
                backgroundImage: `url(${fta.photo})`,
              }}
              title={`${fta.firstName} ${fta.lastName}`}
            />
          ) : (
            <div className="profile-image">
              <i
                title={`${fta.firstName} ${fta.lastName}`}
                style={{ fontSize: "3.5rem" }}
                className={getUserIcon({ isAgent: true })}
              />
            </div>
          )}
        </div>
        <div className="field-values">
          <p>
            <Label>Name</Label>
            <span>
              {fta.firstName} {fta.lastName}
            </span>
          </p>
          <p>
            <Label>Email</Label>
            <span>{fta.email}</span>
          </p>
        </div>
      </div>
    );
  }
}

export default MyAgent;
