import Logo from "#Components/Logo";
import { getUserIcon, logoutUser } from "#hoc/util";
import withRouter from "#hoc/withRouter";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";

const HeaderButton = (props) => {
  const { label, icon, disabled, onClick = () => {} } = props;
  return (
    <div className="client-buttons me-2">
      <Button disabled={disabled} onClick={onClick} color="primary" size="sm">
        <i key={icon} className={`fa ${icon}`} />{" "}
        <span className="button-label">{label}</span>
      </Button>
    </div>
  );
};

const ClientMenu = (props) => {
  const { user, userRoleInOrg, onMenuClick, onLogoutClick } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="link" style={{ padding: "0 0.5rem" }}>
        {user.photo ? (
          <img
            className="rounded-circle profile-image-btn"
            title={`${user.firstName} ${user.lastName}`}
            alt={`${user.firstName} ${user.lastName}`}
            src={user.photo}
          />
        ) : (
          <i
            title={`${user.firstName} ${user.lastName}`}
            style={{ fontSize: "2.5rem" }}
            className={getUserIcon(userRoleInOrg)}
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="admin-dropdown">
        <DropdownItem
          className="border-bottom"
          onClick={() => onMenuClick("/myprofile")}
        >
          <span>
            <i className={getUserIcon(userRoleInOrg)} />
          </span>
          <span>My Profile</span>
        </DropdownItem>
        <DropdownItem
          className="border-bottom"
          onClick={() => onMenuClick("/myorg")}
        >
          <span>
            <i className="fa fa-building" />
          </span>
          <span>My Organization</span>
        </DropdownItem>
        <DropdownItem
          className="border-bottom"
          onClick={() => onMenuClick("/myagent")}
        >
          <span>
            <i className={getUserIcon(userRoleInOrg)} />
          </span>
          <span>My Agent</span>
        </DropdownItem>
        {userRoleInOrg.isClientAdmin ? (
          <DropdownItem
            className="border-bottom"
            onClick={() => onMenuClick("/payment")}
          >
            <span>
              <i className="fa fa-credit-card" />
            </span>
            <span>Payment Method</span>
          </DropdownItem>
        ) : null}
        <DropdownItem onClick={onLogoutClick}>
          <span>
            <i className="fa fa-power-off" />
          </span>
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const AgentMenu = (props) => {
  const { user, userRoleInOrg, onMenuClick, onLogoutClick } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="link" style={{ padding: "0 0.5rem" }}>
        {user.photo ? (
          <img
            className="rounded-circle profile-image-btn"
            title={`${user.firstName} ${user.lastName}`}
            alt={`${user.firstName} ${user.lastName}`}
            src={user.photo}
          />
        ) : (
          <i
            title={`${user.firstName} ${user.lastName}`}
            style={{ fontSize: "2.5rem" }}
            className={getUserIcon(userRoleInOrg)}
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="admin-dropdown">
        <DropdownItem className="border-bottom" onClick={onMenuClick}>
          <span>
            <i className={getUserIcon(userRoleInOrg)} />
          </span>
          <span>My Profile</span>
        </DropdownItem>
        <DropdownItem onClick={onLogoutClick}>
          <span>
            <i className="fa fa-power-off" />
          </span>
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const Header = (props) => {
  const { user, userRoleInOrg } = JSON.parse(
    sessionStorage.getItem("Clouve.object"),
  );
  const [showCreateSupport, setShowCreateSupport] = useState(false);
  const [showCreateDeployment, setShowCreateDeployment] = useState(false);
  const { location, history, toggleSidebar, showSidebar } = props;

  useEffect(() => {
    checkRoute();
  }, []);

  useEffect(() => {
    checkRoute();
  }, [location]);

  const checkRoute = () => {
    setShowCreateSupport(!!location.pathname.match(/^\/tickets/gi));
    setShowCreateDeployment(!!location.pathname.match(/^\/(deployment|$)/gi));
  };

  const onCreateDeploymentClick = () => {
    history.push("/deployment/create");
  };

  const onCreateSupportClick = () => {
    history.push("/support/create");
  };

  const onLogoutClick = async () => {
    await logoutUser(props);
  };

  const onMenuClick = (hashId) => {
    history.push("/profile");
    if (hashId) {
      window.location.hash = hashId;
    }
  };

  const renderUserMenu = () => {
    const { user, org, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    if (userRoleInOrg.isClient) {
      return (
        <ClientMenu
          user={user}
          org={org}
          userRoleInOrg={userRoleInOrg}
          onMenuClick={onMenuClick}
          onLogoutClick={onLogoutClick}
        />
      );
    }
    if (
      userRoleInOrg.isSuperAdmin ||
      userRoleInOrg.isSalesAgent ||
      userRoleInOrg.isAgent
    ) {
      return (
        <AgentMenu
          user={user}
          userRoleInOrg={userRoleInOrg}
          onMenuClick={onMenuClick}
          onLogoutClick={onLogoutClick}
        />
      );
    }
  };

  const renderUserButtons = () => {
    return (
      <>
        <HeaderButton label="Menu" icon="fa-bars" onClick={toggleSidebar} />
        {showCreateSupport && userRoleInOrg.isClient ? (
          <HeaderButton
            label="Create Ticket"
            icon="fa-ticket"
            onClick={onCreateSupportClick}
          />
        ) : null}
        {showCreateDeployment && userRoleInOrg.isClient ? (
          <HeaderButton
            label="Marketplace"
            icon="fa-store"
            onClick={onCreateDeploymentClick}
          />
        ) : null}
      </>
    );
  };

  return (
    <header className="header ps-3 pe-3">
      <Row>
        <Col className="d-flex align-items-center">
          <Logo />
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          {renderUserButtons()}
          {renderUserMenu()}
        </Col>
      </Row>
    </header>
  );
};

export default withAlert()(withRouter(Header));
