import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export const OrgBrand = ({ org = {}, className = "", onClick }) => {
  const { user } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  const classNames = ["org-brand", ...className.split(" ")];
  const isAuthorizedUser = org.id !== user.orgId;
  return (
    <div className={classNames.join(" ")} onClick={onClick}>
      {org.logo ? (
        <img
          className="rounded-circle profile-image-btn"
          style={{ width: "2rem", height: "2rem" }}
          title={`${org.name}`}
          alt={`${org.name}`}
          src={org.logo}
        />
      ) : (
        <i
          title={`${org.name}`}
          style={{ fontSize: "100%", width: "2rem", height: "2rem" }}
          className={
            isAuthorizedUser
              ? "brand-icon fa-regular fa-building"
              : "brand-icon fa-solid fa-building"
          }
        />
      )}{" "}
      <span className="brand-title text-truncate ps-2">{org.name}</span>
    </div>
  );
};

export const OrgSelector = ({
  orgOptions = [],
  selectedOrgId,
  onSwitchOrg = (x) => {},
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState(selectedOrgId);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const selectedOrg = orgOptions.find(({ id }) => id === currentOrgId);
  useEffect(() => {
    setCurrentOrgId(selectedOrgId);
  }, [selectedOrgId]);

  const onItemClick = (org) => {
    setCurrentOrgId(org.id);
    onSwitchOrg(org);
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="p-2 w-100">
      <DropdownToggle
        caret
        color="light"
        className="d-flex align-items-center w-100 p-1"
      >
        <OrgBrand org={selectedOrg} className="brown" />
      </DropdownToggle>
      <DropdownMenu
        container="body"
        style={{ position: "fixed", zIndex: 1050 }}
      >
        {orgOptions
          .filter(({ id }) => id !== currentOrgId)
          .map((authOrg, idx) => (
            <DropdownItem key={idx} onClick={() => onItemClick(authOrg)}>
              <OrgBrand org={authOrg} />
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};
