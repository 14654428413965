import { gql } from "@apollo/client";

export const TICKETSBYSTATE = gql`
  query ticketsByState(
    $orgId: Int
    $currMonth: Boolean
    $createdBy: CreatedBy
    $assignedTo: AssignedTo
    $ticketState: String
    $ticketType: String
  ) {
    ticketsByState(
      orgId: $orgId
      currMonth: $currMonth
      createdBy: $createdBy
      assignedTo: $assignedTo
      ticketState: $ticketState
      ticketType: $ticketType
    ) {
      id
      title
      type
      state
      description
      priority
      urgency
      jsonSchema
      fta {
        id
        firstName
        lastName
        email
      }
      orgTicketId
      estimatedTime
      estimatedStatus
      currentAssignee {
        id
        firstName
        lastName
        email
      }
      comments
      attachments
      extraAttr
      updatedAt
      createdAt
      createdByUser {
        id
        firstName
        lastName
        email
      }
      org {
        id
        name
        email
      }
      deployment {
        id
        subscriptionStatus
        deploymentAction
      }
    }
  }
`;
