import InputForm, {
  PasswordField,
  SubmitButton,
  SwitchField,
  TextField,
} from "#Components/InputForm";
import Logo from "#Components/Logo.jsx";
import { CREATEORGANIZATION } from "#Graphql";
import withRouter from "#hoc/withRouter";
import { SignUpValidator } from "#pages/Account/helpers";
import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { withAlert } from "react-alert";

import { Link } from "react-router-dom";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";

const SignUp = (props) => {
  const [verifyEmail, setVerifyEmail] = useState("");
  const [createOrganization, { data, loading: isLoading, error }] =
    useMutation(CREATEORGANIZATION);

  useEffect(() => {
    if (data?.createOrganization?.code === 201) {
      props.alert.success(data.createOrganization.message);
      props.history.push("/signup/success");
    }
  }, [data]);

  useEffect(() => {
    error && props.alert.error(error.message || "Unknown Error");
  }, [error]);

  const handleSubmit = async ({ formFields }) => {
    if (!isLoading) {
      grecaptcha.enterprise.ready(async () => {
        const captchaToken = await grecaptcha.enterprise.execute(
          VITE_GOOGLE_RECAPTCHA_ID,
          { action: "signup" },
        );
        await createOrganization({
          variables: {
            input: {
              ...formFields,
              captchaToken,
            },
          },
        });
        setVerifyEmail(formFields.email);
      });
    }
  };

  const renderSignupForm = () => {
    const { protocol, hostname, port } = window.location;
    const homeURL =
      protocol +
      "//" +
      hostname.replace(/app\./gi, "") +
      (port ? ":" + port : "");
    const classNames = ["signup", "form"];
    isLoading && classNames.push("spinner");
    return (
      <div className={classNames.join(" ")}>
        <Logo />
        <h5 className="text-center m-4">Create a Clouve account</h5>
        <InputForm validator={SignUpValidator} onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup className="position-relative">
                <Label for="firstName">First Name*</Label>
                <TextField id="firstName" name="firstName" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastName">Last Name*</Label>
                <TextField id="lastName" name="lastName" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="email">
                <Label for="email">Email*</Label>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  validateOnBlur
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="orgName">Organization Name*</Label>
                <TextField id="orgName" name="orgName" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup id="password" className="position-relative">
                <Label for="newPassword">Password*</Label>
                <PasswordField
                  id="newPassword"
                  name="newPassword"
                  showStrengthBar={true}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="position-relative">
                <Label for="confirmPassword">Confirm Password*</Label>
                <PasswordField id="confirmPassword" name="confirmPassword" />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="sa-checkbox">
            <label>
              <SwitchField
                id="agreement"
                name="agreement"
                label={
                  <>
                    <span>I have read and agree to the</span>{" "}
                    <a href={homeURL + "/terms"} target="_blank">
                      {" "}
                      Terms of Service{" "}
                    </a>
                  </>
                }
              />
            </label>
          </FormGroup>
          <FormGroup>
            <div className="subscribe-btn">
              <SubmitButton
                id="submitButton"
                color="primary"
                size="lg"
                block={true}
                disabled={isLoading}
              >
                Get Started
              </SubmitButton>
            </div>
          </FormGroup>
        </InputForm>
        <div className="form-foooter">
          <div className="login-link">
            <span>Already have an account? </span>
            <Link to={"/login"}>Sign In</Link>
          </div>
        </div>
      </div>
    );
  };

  const renderSignupSuccess = () => {
    const { history } = props;
    return (
      <div className="signup form">
        <div className="container">
          <div className="form-container">
            <div className="mail-sent text-center">
              <Logo />
              <h4>Welcome to Clouve</h4>
              <div className="instructions">
                <p>An email has been sent to</p>
                <p>
                  <b>{verifyEmail}</b>
                </p>
                <p>Please take a moment to verify your email</p>
                <Button
                  onClick={() => {
                    history.push("/login");
                  }}
                  block
                  size="lg"
                  color="primary"
                  type="button"
                >
                  Back to Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (window.location.pathname === "/signup/success") {
    return renderSignupSuccess();
  }
  return renderSignupForm();
};

export default withAlert()(withRouter(SignUp));
