import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login(
    $captchaToken: String!
    $email: String!
    $password: String!
    $loginTime: Date
  ) {
    login(
      captchaToken: $captchaToken
      email: $email
      password: $password
      loginTime: $loginTime
    )
  }
`;
