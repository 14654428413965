import { InfoColumn, InfoTable } from "#Components/InfoTable";
import Switch from "#Components/Switch";
import { TICKET_TYPES } from "#Constants/TicketFields";

import { cloudPlatform, renderTicketStatus } from "#hoc/util";
import withRouter from "#hoc/withRouter";
import { getTicketsLocation } from "#pages/Tickets";
import moment from "moment";
import React, { PureComponent } from "react";

import { Col, Row } from "reactstrap";

class TicketsList extends PureComponent {
  state = {
    createdBy: "SELF",
    assignedTo: "SELF",
    assignedToMe: true,
    assignedToOthers: false,
    showTickets: true,
    showDeployments: true,
    showDeleted: false,
  };

  componentDidMount() {
    const { params, ticketFilterProps } = this.props;
    const { assignedTo } = ticketFilterProps;
    const { ticketType } = params;
    const assignedToMe =
      assignedTo === "SELF" || assignedTo === "ALL" || !assignedTo;
    const assignedToOthers =
      assignedTo === "PTHERS" || assignedTo === "ALL" || !assignedTo;
    const showTickets =
      ticketType === TICKET_TYPES.support ||
      ticketType === "all" ||
      !ticketType;
    const showDeployments =
      ticketType === TICKET_TYPES.deployment ||
      ticketType === "all" ||
      !ticketType;
    this.setState({
      assignedToMe,
      assignedToOthers,
      showTickets,
      showDeployments,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history, params, ticketFilterProps } = this.props;
    const { assignedToMe, assignedToOthers, showTickets, showDeployments } =
      this.state;
    if (
      prevState.assignedToMe !== assignedToMe ||
      prevState.assignedToOthers !== assignedToOthers
    ) {
      const { handleAssignedTo } = ticketFilterProps;
      const assignedTo =
        assignedToMe && !assignedToOthers
          ? "SELF"
          : assignedToOthers && !assignedToMe
            ? "OTHERS"
            : "ALL";
      handleAssignedTo(assignedTo);
    }
    if (
      prevState.showTickets !== showTickets ||
      prevState.showDeployments !== showDeployments
    ) {
      const ticketType =
        showTickets && !showDeployments
          ? TICKET_TYPES.support
          : showDeployments && !showTickets
            ? TICKET_TYPES.deployment
            : "all";
      history.push(
        getTicketsLocation({
          params,
          overrideParams: {
            ticketType,
          },
        }),
      );
    }
  }

  handleAssignedToMe = () => {
    const { assignedToMe, assignedToOthers } = this.state;
    this.setState({
      assignedToMe: !assignedToMe,
      assignedToOthers: assignedToMe ? true : assignedToOthers,
    });
  };

  handleAssignedToOthers = () => {
    const { assignedToMe, assignedToOthers } = this.state;
    this.setState({
      assignedToOthers: !assignedToOthers,
      assignedToMe: assignedToOthers ? true : assignedToMe,
    });
  };

  handleShowTickets = () => {
    const { showTickets, showDeployments } = this.state;
    this.setState({
      showTickets: !showTickets,
      showDeployments: showTickets ? true : showDeployments,
    });
  };

  handleShowDeployments = () => {
    const { showTickets, showDeployments } = this.state;
    this.setState({
      showDeployments: !showDeployments,
      showTickets: showDeployments ? true : showTickets,
    });
  };

  handleShowDeleted = () => {
    const { showDeleted } = this.state;
    this.setState({ showDeleted: !showDeleted });
  };

  renderFilterToggles = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const {
      assignedToMe,
      assignedToOthers,
      showTickets,
      showDeployments,
      showDeleted,
    } = this.state;
    const { currMonth, handleCurrentMonth, createdBy, handleCreatedByMe } =
      this.props.ticketFilterProps;

    if (userRoleInOrg.isAgent) {
      return (
        <Row className="justify-content-end">
          <Col xs="auto">
            <Switch
              id="support"
              name="support"
              label="Show Tickets"
              checked={showTickets}
              onChange={this.handleShowTickets}
            />
            <Switch
              id="deployment"
              name="deployment"
              label="Show Deployments"
              checked={showDeployments}
              onChange={this.handleShowDeployments}
            />
            <Switch
              id="showDeleted"
              name="showDeleted"
              label="Show Deleted"
              checked={showDeleted}
              onChange={this.handleShowDeleted}
            />
          </Col>
          {userRoleInOrg.isAgentAdmin ? (
            <Col xs="auto">
              <Switch
                id="agentCurrMonth"
                name="currMonth"
                label="Current Month"
                checked={currMonth}
                onChange={handleCurrentMonth}
              />
              <Switch
                id="assignedTo"
                name="assignedTo"
                label="Assigned To Me"
                checked={assignedToMe}
                onChange={this.handleAssignedToMe}
              />
              <Switch
                id="assignedToOthers"
                name="assignedToOthers"
                label="Assigned To Others"
                checked={assignedToOthers}
                onChange={this.handleAssignedToOthers}
              />
            </Col>
          ) : null}
        </Row>
      );
    }

    if (userRoleInOrg.isClient) {
      return (
        <Row className="justify-content-end">
          <Col xs="auto">
            <Switch
              id="clientCurrMonth"
              name="currMonth"
              label="Current Month"
              checked={currMonth}
              onChange={handleCurrentMonth}
            />
          </Col>
          {userRoleInOrg.isClientAdmin ? (
            <Col xs="auto">
              <Switch
                id="clientCreatedByMe"
                name="createdBy"
                label="Created By Me"
                checked={createdBy === "SELF"}
                onChange={handleCreatedByMe}
              />
            </Col>
          ) : null}
        </Row>
      );
    }
  };

  onRowClicked = (ticket) => {
    this.props.history.push(
      `/${ticket?.type?.toLowerCase()}/${ticket?.orgTicketId}`,
    );
  };

  renderTicketList = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketFilterProps, isLoading } = this.props;
    const { showDeleted } = this.state;
    const { ticketsByState = [] } = ticketFilterProps;
    const renderTicketIcon = (ticket, index, column, id) => {
      if (ticket.type === "SUPPORT") {
        return (
          <div className="cell-ticket-type" title={ticket.orgTicketId}>
            <i className="fa fa-ticket pink" />
          </div>
        );
      } else if (ticket.type === "DEPLOYMENT") {
        const iconColor = ticket.deployment?.deploymentAction?.match(
          /^delete$/gi,
        )
          ? "red"
          : "blue";
        return (
          <span
            className="cell-ticket-type deployment"
            title={ticket.orgTicketId}
          >
            <i className={`fa fa-server ${iconColor}`} />
          </span>
        );
      } else {
        return null;
      }
    };

    const dateFormat = (value) => {
      return moment(value).format("MMM DD, YYYY h:mm A");
    };

    const data = ticketsByState
      .filter(({ deployment }) => {
        if (showDeleted) {
          return deployment?.deploymentAction?.match(/^delete$/gi);
        }
        return !deployment?.deploymentAction?.match(/^delete$/gi);
      })
      .map((tkt) => ({
        ...tkt,
        csp: cloudPlatform(tkt),
        ns: `org-${tkt.org?.id}-tkt-${tkt.id}`,
      }));

    return (
      <InfoTable
        data={data}
        toolboxComponent={this.renderFilterToggles()}
        onRowClicked={this.onRowClicked}
        pagination={data.length > 10}
        highlightOnHover
        pointerOnHover
        progressPending={isLoading}
      >
        <InfoColumn
          selector={(ticket) => ticket.id}
          cell={renderTicketIcon}
          width="30px"
          compact
          center
        />
        <InfoColumn selector={(ticket) => ticket.orgTicketId} grow={2} sortable>
          Ticket ID
        </InfoColumn>
        <InfoColumn selector={(ticket) => ticket.title} grow={2} sortable>
          Title
        </InfoColumn>
        <InfoColumn
          selector={(ticket) => ticket.state}
          cell={renderTicketStatus}
          width="150px"
          sortable
        >
          Status
        </InfoColumn>
        <InfoColumn
          selector={(ticket) => ticket.urgency}
          format={(ticket) => ticket.urgency?.toUpperCase()}
          sortable
        >
          Urgency
        </InfoColumn>
        <InfoColumn
          selector={(ticket) => ticket.priority}
          format={(ticket) => ticket.priority?.toUpperCase()}
          sortable
        >
          Priority
        </InfoColumn>
        {userRoleInOrg.isAgent ? (
          <InfoColumn selector={(ticket) => ticket.ns} grow={2} sortable>
            Namespace
          </InfoColumn>
        ) : null}
        {userRoleInOrg.isAgent ? (
          <InfoColumn selector={(ticket) => ticket.csp} grow={2} sortable>
            Cloud Platform
          </InfoColumn>
        ) : null}
        <InfoColumn
          selector={(ticket) =>
            `${ticket.createdByUser?.firstName} ${ticket.createdByUser?.lastName}`
          }
          sortable
        >
          Created By
        </InfoColumn>
        <InfoColumn
          selector={(ticket) =>
            `${ticket.currentAssignee?.firstName} ${ticket.currentAssignee?.lastName}`
          }
          sortable
        >
          Assigned Agent
        </InfoColumn>
        <InfoColumn
          selector={(ticket) => ticket.createdAt}
          format={(ticket) => `${dateFormat(ticket.createdAt)}`}
          sortable
          center
          width="200px"
        >
          Date Created
        </InfoColumn>
        <InfoColumn
          selector={(ticket) => ticket.updatedAt}
          format={(ticket) => `${dateFormat(ticket.updatedAt)}`}
          sortable
          center
          width="200px"
        >
          Date Updated
        </InfoColumn>
      </InfoTable>
    );
  };

  render() {
    return <div>{this.renderTicketList()}</div>;
  }
}

export default withRouter(TicketsList);
