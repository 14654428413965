import { gql } from "@apollo/client";

export const ORGANIZATIONS = gql`
  query organizations {
    organizations {
      id
      name
      logo
      shortName
      website
      phone
      fax
      exempt
      firstTierAgent
      orgUsers
      historyCount
      subscription
      lastLoginAt
      createdAt
      updatedAt
      orgSnapshot {
        open
        openCurrMonth
        inProgress
        inProgressCurrMonth
        resolved
        resolvedCurrMonth
        closed
        closedCurrMonth
      }
      authorizedUsers
    }
  }
`;
