import ErrorBoundary from "#Components/ErrorBoundary";
import { TICKET_TYPES } from "#Constants/TicketFields";
import { fetchTickets } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import Snapshot from "#pages/Tickets/components/Snapshot";
import React, { PureComponent } from "react";
import { Card } from "reactstrap";
import TicketsList from "./TicketsList";

export const getTicketsLocation = ({ params = {}, overrideParams = {} }) => {
  const pathParts = ["", "tickets"];
  pathParts.push(
    overrideParams.ticketType?.toLowerCase() ||
      params.ticketType?.toLowerCase() ||
      "all",
  );
  console.log(overrideParams.ticketState);
  pathParts.push(
    overrideParams.ticketState?.toLowerCase() ||
      params.ticketState?.toLowerCase() ||
      "all",
  );
  if (overrideParams.orgId) {
    pathParts.push(overrideParams.orgId.toString());
  } else if (params.orgId) {
    pathParts.push(params.orgId.toString());
  }
  return pathParts.join("/");
};

class Tickets extends PureComponent {
  state = {
    isLoading: false,
    currMonth: true,
    createdBy: "SELF",
    assignedTo: "SELF",
    ticketsByState: [],
    ticketListLoading: false,
  };
  watchList = ["currMonth", "createdBy", "assignedTo"];

  componentDidMount() {
    const { location } = this.props;
    if (location?.state?.currentMonth !== undefined) {
      this.setState({ currMonth: location?.state?.currentMonth });
    }
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    if (location?.state?.currentMonth !== undefined) {
      if (
        location?.state?.currentMonth !==
        prevProps.location?.state?.currentMonth
      ) {
        this.setState({ currMonth: location?.state?.currentMonth });
      }
    }
    if (location?.key !== prevProps.location?.key) {
      this.loadData();
    }
    this.watchList.every((attr) => {
      if (prevState[attr] !== this.state[attr]) {
        this.loadData();
        return false;
      }
      return true;
    });
  }

  loadData = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { currMonth, createdBy, assignedTo } = this.state;
    this.setState({ isLoading: true });
    if (userRoleInOrg.isAgent) {
      const { params } = this.props;
      const { ticketType, ticketState, orgId } = params;
      fetchTickets({
        ticketType: ticketType === "all" ? null : ticketType || null,
        ticketState: ticketState === "all" ? null : ticketState || null,
        orgId: orgId ? parseInt(orgId) : null,
        currMonth,
        assignedTo,
      })
        .then(({ ticketsByState }) => {
          this.setState({
            ticketsByState,
            ticketListLoading: false,
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    }
    if (userRoleInOrg.isClient) {
      fetchTickets({
        ticketType: TICKET_TYPES.support,
        currMonth,
        createdBy,
      })
        .then(({ ticketsByState }) => {
          this.setState({
            ticketsByState,
            ticketListLoading: false,
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          console.log(err);
        });
    }
  };

  handleCurrentMonth = (currMonth) => {
    this.setState({ currMonth });
  };

  handleCreatedByMe = (createdBy) => {
    this.setState({
      createdBy: createdBy ? "SELF" : "ALL",
    });
  };

  handleAssignedTo = (newAssignedTo) => {
    const { assignedTo } = this.state;
    this.setState({
      assignedTo: assignedTo === newAssignedTo ? "ALL" : newAssignedTo,
    });
  };

  render() {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { params } = this.props;
    const {
      isLoading,
      ticketsByState,
      ticketListLoading,
      currMonth,
      createdBy,
      assignedTo,
    } = this.state;

    const ticketFilterProps = {
      ticketsByState,
      ticketListLoading,
      currMonth,
      handleCurrentMonth: this.handleCurrentMonth,
      createdBy,
      handleCreatedByMe: this.handleCreatedByMe,
      assignedTo,
      handleAssignedTo: this.handleAssignedTo,
    };
    const classnames = ["ticket-tbl"];
    userRoleInOrg.isAgent && classnames.push("with-snapshot");

    return (
      <Card className="content-wrapper ticket-wrapper">
        {userRoleInOrg.isAgent && (
          <Snapshot currMonth={currMonth} assignedTo={assignedTo} />
        )}
        <ErrorBoundary error="bg-error">
          <div className={classnames.join(" ")}>
            <TicketsList
              ticketFilterProps={ticketFilterProps}
              params={params}
              isLoading={isLoading}
            />
          </div>
        </ErrorBoundary>
      </Card>
    );
  }
}

export default withRouter(Tickets);
