import { gql } from "@apollo/client";

export const TICKETBYID = gql`
  query ticketById(
    $ticketId: Int
    $orgTicketId: String
    $updatePlan: Boolean
    $refreshAgentToken: Boolean
    $refreshBuildStatus: Boolean
    $refreshDeploymentStatus: Boolean
  ) {
    ticketById(
      ticketId: $ticketId
      orgTicketId: $orgTicketId
      updatePlan: $updatePlan
      refreshAgentToken: $refreshAgentToken
      refreshBuildStatus: $refreshBuildStatus
      refreshDeploymentStatus: $refreshDeploymentStatus
    ) {
      deployment {
        id
        orgId
        ticket {
          orgTicketId
          title
        }
        subscriptionId
        subscriptionStatus
        subscriptionDetails
        planId
        trialExpiresAt
        deploymentAction
        agreementUrl
        createdAt
        updatedAt
      }
      id
      type
      state
      title
      description
      priority
      urgency
      jsonSchema
      fta {
        id
        firstName
        lastName
        email
      }
      orgTicketId
      estimatedTime
      estimatedStatus
      currentAssignee {
        id
        firstName
        lastName
        email
      }
      comments
      attachments
      extraAttr
      updatedAt
      createdAt
      createdByUser {
        id
        firstName
        lastName
      }
      selfServiceBuildStatus
      deploymentStatus
      selfServiceBuildLogs
      deploymentDetails
      agentToken
    }
  }
`;
