import { gql } from "@apollo/client";

export const ORGUSERSLIST = gql`
  query orgUsersList {
    orgUsersList {
      id
      email
      firstName
      lastName
      orgId
      photo
      enabled
      organization {
        id
      }
      organizationRole {
        id
        role
      }
      authorizedRole {
        id
        role
      }
    }
  }
`;
