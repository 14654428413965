import { InfoColumn, InfoTable } from "#Components/InfoTable";
import Switch from "#Components/Switch";
import { TICKET_STATES } from "#Constants/TicketFields";
import { submitDeleteOrganization, submitToggleExempt } from "#Graphql/mutate";
import { fetchOrganizations } from "#Graphql/query";
import { downloadContentAsFile } from "#hoc/util";
import withRouter from "#hoc/withRouter";
import { getTicketsLocation } from "#pages/Tickets";
import { Parser } from "@json2csv/plainjs";
import { unwind } from "@json2csv/transforms";
import { isEqual } from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";

import {
  Button,
  ButtonDropdown,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import AgentListModal from "./AgentListModal";
import OrgDetailsModal from "./OrgDetailsModal";
import OrgUsersModal from "./OrgUsersModal";

class ClientsList extends PureComponent {
  state = {
    orgStatuses: [],
    showAssignToFTAPopup: false,
    showOrgDetails: false,
    orgItem: null,
    orgUsers: null,
    orgUsersTitle: "",
    selectedItems: [],
    exportDropdownOpen: false,
    currMonth: false,
  };

  async componentDidMount() {
    await this.loadOrganizations();
  }

  loadOrganizations = async () => {
    try {
      const { organizations } = await fetchOrganizations();
      const { orgItem: oldOrgItem } = this.state;
      const orgStatuses = organizations.map((orgItem) =>
        this.getOrgStatus(orgItem),
      );
      if (oldOrgItem) {
        const orgStatus = orgStatuses.filter(
          ({ orgItem }) => orgItem.id === oldOrgItem.id,
        )[0];
        const { orgItem } = orgStatus;
        this.setState({ orgItem });
      }
      this.setState({ orgStatuses });
    } catch (err) {
      this.errorHandler(err);
    }
  };

  getOrgStatus = (orgItem) => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const {
      firstTierAgent = {},
      historyCount = 0,
      orgUsers = [],
      authorizedUsers = [],
      subscription = {},
      orgSnapshot: tickets,
    } = orgItem;
    const { status, subscriptionId, subsPlanId } = subscription || {};
    let ftaName = "NONE";
    let ftaEmail = "NONE";
    let fta = null;
    if (firstTierAgent) {
      ftaName =
        firstTierAgent.firstName && firstTierAgent.lastName
          ? `${firstTierAgent.firstName} ${firstTierAgent.lastName}`
          : "";
      ftaEmail = firstTierAgent.email || "";
      fta = {
        ftaName,
        ftaEmail,
      };
    }
    let users = null;
    let isDeletable = false;
    if (orgUsers) {
      users = {
        all: [],
        enabledUsers: [],
        disabledUsers: [],
        deletedUsers: [],
        verifiedUsers: [],
        unverifiedUsers: [],
      };
      [...orgUsers, ...authorizedUsers].forEach((userObj) => {
        users.all.push(userObj);
        userObj.enabled && users.enabledUsers.push(userObj);
        !userObj.enabled && users.disabledUsers.push(userObj);
        userObj.deleted && users.deletedUsers.push(userObj);
        userObj.emailVerified && users.verifiedUsers.push(userObj);
        !userObj.emailVerified && users.unverifiedUsers.push(userObj);
      });
      if (
        userRoleInOrg.isSuperAdmin &&
        [status, subscriptionId, subsPlanId].every((elem) => !elem) &&
        historyCount === 0 &&
        users.verifiedUsers.length === 0
      ) {
        isDeletable = true;
      }
    }
    return {
      orgItem,
      fta,
      users,
      isDeletable,
      tickets,
    };
  };

  getOrgDetailsCSV = (userCategory = "all") => {
    const { orgStatuses = [] } = this.state;
    const orgsToExport = orgStatuses.filter(({ orgItem }) =>
      this.isItemSelected(orgItem.id),
    );
    const fields = [
      {
        label: "Organization Name",
        value: "orgItem.name",
      },
      {
        label: "Organization Website",
        value: "orgItem.website",
      },
      {
        label: "Organization Phone",
        value: "orgItem.phone",
      },
      {
        label: "Organization Address",
        value: "orgItem.address",
      },
      {
        label: "First Name",
        value: `users.${userCategory}.firstName`,
      },
      {
        label: "Last Name",
        value: `users.${userCategory}.lastName`,
      },
      {
        label: "Email",
        value: `users.${userCategory}.email`,
      },
      {
        label: "Phone",
        value: `users.${userCategory}.phone`,
      },
      {
        label: "Enabled",
        value: `users.${userCategory}.enabled`,
      },
      {
        label: "Deleted",
        value: `users.${userCategory}.deleted`,
      },
      {
        label: "Verified Email",
        value: `users.${userCategory}.emailVerified`,
      },
    ];
    const transforms = [unwind({ paths: [`users.${userCategory}`] })];
    const json2csv = new Parser({ fields, transforms });
    return json2csv.parse(orgsToExport);
  };

  toggleAssignToFTAPopup = () => {
    const { showAssignToFTAPopup } = this.state;
    this.setState({
      showAssignToFTAPopup: !showAssignToFTAPopup,
    });
  };

  onAssignFTA = async () => {
    this.toggleAssignToFTAPopup();
    await this.loadOrganizations();
  };

  handleShowOrgDetails = (showOrgDetails, orgItem) => {
    this.setState({ showOrgDetails, orgItem });
  };

  handleShowOrgUsers = (orgUsers, orgUsersTitle) => {
    this.setState({ orgUsers, orgUsersTitle });
  };

  isItemSelected = (id) => {
    const { selectedItems } = this.state;
    return selectedItems.includes(id);
  };

  handleOrgTicketState = (ticketState, orgId, currentMonth) => {
    const { history, params } = this.props;
    history.push(
      getTicketsLocation({
        params,
        overrideParams: {
          ticketState,
          orgId,
        },
      }),
      { currentMonth },
    );
  };

  errorHandler = (error) => {
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.props.alert.success(message);
  };

  handleDeleteOrg = async (orgItem) => {
    const confirmed = await confirm({
      title: `Delete ${orgItem.name} Organization`,
      message: (
        <>
          Are you sure you want to DELETE <strong>{orgItem.name}</strong> and
          all of its users?
        </>
      ),
      confirmText: "Delete",
      confirmColor: "danger",
      cancelText: "Cancel",
      cancelColor: "link",
    });
    if (confirmed) {
      try {
        const { deleteOrganization } = await submitDeleteOrganization({
          orgId: orgItem.id,
        });
        if (deleteOrganization && deleteOrganization.code === 200) {
          this.showSuccessMessage(deleteOrganization.message);
          await this.loadOrganizations();
        }
      } catch (err) {
        this.errorHandler(err);
      }
    }
  };

  handleExemptToggle = async (orgItem) => {
    try {
      const { toggleExempt } = await submitToggleExempt({ orgId: orgItem.id });
      if (toggleExempt && toggleExempt.code === 200) {
        this.showSuccessMessage(toggleExempt.message);
        await this.loadOrganizations();
      } else {
        this.errorHandler(toggleExempt);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  toggleExportDropdown = () => {
    this.setState({ exportDropdownOpen: !this.state.exportDropdownOpen });
  };

  renderToolbox = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const {
      orgStatuses,
      showAssignToFTAPopup,
      selectedItems,
      exportDropdownOpen,
      currMonth,
    } = this.state;
    const isDisabled = !selectedItems.length;
    return orgStatuses.length > 0 ? (
      <Row className="justify-content-end">
        {userRoleInOrg.isAgentAdmin ? (
          <Col xs="auto">
            <Switch
              id="ticketsCurrMonth"
              name="currMonth"
              label="Current Month"
              checked={currMonth}
              onChange={(currMonth) => {
                this.setState({ currMonth });
              }}
            />
          </Col>
        ) : null}
        <Col xs="auto">
          {(userRoleInOrg.isSuperAdmin || userRoleInOrg.isAgentAdmin) && (
            <Button
              disabled={isDisabled}
              size="sm"
              color="primary"
              onClick={this.toggleAssignToFTAPopup}
            >
              Assign
            </Button>
          )}{" "}
          <ButtonDropdown
            size="sm"
            isOpen={exportDropdownOpen}
            toggle={this.toggleExportDropdown}
          >
            <DropdownToggle disabled={isDisabled} color="primary" caret>
              Export
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  downloadContentAsFile({
                    fileName: "clouve-all-users.csv",
                    content: this.getOrgDetailsCSV(),
                  });
                }}
              >
                All Users
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  downloadContentAsFile({
                    fileName: "clouve-enabled-users.csv",
                    content: this.getOrgDetailsCSV("enabledUsers"),
                  });
                }}
              >
                Enabled Users
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  downloadContentAsFile({
                    fileName: "clouve-disabled-users.csv",
                    content: this.getOrgDetailsCSV("disabledUsers"),
                  });
                }}
              >
                Disabled Users
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  downloadContentAsFile({
                    fileName: "clouve-verified-users.csv",
                    content: this.getOrgDetailsCSV("verifiedUsers"),
                  });
                }}
              >
                Verified Users
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  downloadContentAsFile({
                    fileName: "clouve-unverified-users.csv",
                    content: this.getOrgDetailsCSV("unverifiedUsers"),
                  });
                }}
              >
                Unverified Users
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  downloadContentAsFile({
                    fileName: "clouve-deleted-users.csv",
                    content: this.getOrgDetailsCSV("deletedUsers"),
                  });
                }}
              >
                Deleted Users
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
          <AgentListModal
            showModal={showAssignToFTAPopup}
            orgId={selectedItems}
            toggle={this.toggleAssignToFTAPopup}
            onAssign={this.onAssignFTA}
          />
        </Col>
      </Row>
    ) : null;
  };

  onRowClicked = (row, event) => {
    const handler = event.target.getAttribute("data-handler");
    const handlerParams = event.target.getAttribute("data-handler-params");
    switch (handler) {
      case "showOrgDetails":
        this.handleShowOrgDetails(true, row.orgItem);
        break;
      case "showOrgUsers":
        const [orgName, usersType, label] = handlerParams.split(",");
        this.handleShowOrgUsers(
          row.users[`${usersType}Users`],
          `${orgName} (${label} Users)`,
        );
        break;
      case "showOrgTickets":
        const [ticketType, currentMonth] = handlerParams.split(",");
        this.handleOrgTicketState(
          TICKET_STATES[ticketType],
          row.orgItem.id,
          currentMonth === "true",
        );
        break;
    }
  };

  onSelectedRowsChange = ({ selectedRows }) => {
    const { selectedItems } = this.state;
    const newSelectedItems = selectedRows.map((row) => row.orgItem.id);
    if (!isEqual(selectedItems, newSelectedItems)) {
      this.setState({
        selectedItems: newSelectedItems,
      });
    }
  };

  isRowSelected = (row) => {
    return this.isItemSelected(row.orgItem.id);
  };

  render = () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { loading } = this.props;
    const {
      orgStatuses,
      currMonth,
      showOrgDetails,
      orgItem,
      orgUsers,
      orgUsersTitle,
    } = this.state;

    if (
      !userRoleInOrg.isSuperAdmin &&
      !userRoleInOrg.isAgentAdmin &&
      !userRoleInOrg.isSalesAgent
    ) {
      return (
        <Card className="content-wrapper">
          <h5 className="tab-nav">
            User is not authorized to access this section
          </h5>
        </Card>
      );
    }

    const renderOrgIcon = ({ orgItem: org }) => {
      return org.logo ? (
        <img style={{ width: "3rem", height: "3rem" }} src={org.logo} alt="" />
      ) : (
        <div className="profile-image">
          <i
            title={org.name}
            style={{ fontSize: "3rem" }}
            className="fa-regular fa-building"
          />
        </div>
      );
    };

    const renderOrgExempt = ({ orgItem }) => {
      return (
        <Switch
          checked={orgItem.exempt}
          onChange={async () => {
            await this.handleExemptToggle(orgItem);
          }}
        />
      );
    };

    const renderOrgDelete = ({ isDeletable, orgItem }) => {
      return (
        <Button
          outline
          color="danger"
          disabled={!isDeletable}
          onClick={async () => {
            await this.handleDeleteOrg(orgItem);
          }}
        >
          <i className="fa fa-trash" />
        </Button>
      );
    };

    const renderOrgUsersLink = (org, usersType) => {
      const label = usersType.charAt(0).toUpperCase() + usersType.slice(1);
      const count = org.users[`${usersType}Users`]?.length;
      const props = {};
      if (count > 0) {
        Object.assign(props, {
          className: "inline-link",
          "data-tag": "allowRowEvents",
          "data-handler": "showOrgUsers",
          "data-handler-params": `${org.orgItem.name},${usersType},${label}`,
        });
      }
      return (
        <div className="mt-1 mb-1">
          <span {...props}>
            {label}: {count}
          </span>
        </div>
      );
    };

    const renderOrgTicketsLink = (org, ticketType, currentMonth = false) => {
      const label = ticketType.charAt(0).toUpperCase() + ticketType.slice(1);
      const ticketTypeKey = currentMonth
        ? `${ticketType}CurrMonth`
        : ticketType;
      const count = org.tickets[ticketTypeKey];
      const props = {};
      if (count > 0) {
        Object.assign(props, {
          className: "inline-link",
          "data-tag": "allowRowEvents",
          "data-handler": "showOrgTickets",
          "data-handler-params": `${ticketType},${currentMonth}`,
        });
      }
      return (
        <div className="mt-1 mb-1">
          <span {...props}>
            {label}: {count}
          </span>
        </div>
      );
    };
    orgStatuses.sort((a, b) => a.orgItem.id - b.orgItem.id);
    return (
      <Card className="content-wrapper">
        <div>
          <InfoTable
            data={orgStatuses}
            pagination={orgStatuses.length > 10}
            progressPending={loading}
            selectableRows
            selectableRowsHighlight
            responsive
            onRowClicked={this.onRowClicked}
            toolboxComponent={this.renderToolbox()}
            onSelectedRowsChange={this.onSelectedRowsChange}
            selectableRowSelected={this.isRowSelected}
          >
            <InfoColumn width="3.5rem" cell={renderOrgIcon} center />
            <InfoColumn
              selector={(row) => row.orgItem.name}
              cell={(row) => (
                <span
                  className="inline-link"
                  data-tag="allowRowEvents"
                  data-handler="showOrgDetails"
                  title={row.orgItem.name}
                >
                  {row.orgItem.name}
                </span>
              )}
              sortable
            >
              Org Name
            </InfoColumn>
            {!userRoleInOrg.isSalesAgent ? (
              <InfoColumn
                selector={(row) => `${row.fta?.ftaName} ${row.fta?.ftaEmail}`}
                sortable
                cell={(row) => (
                  <div>
                    <div className="mt-1 mb-1">{row.fta?.ftaName}</div>
                    <div className="mt-1 mb-1">{row.fta?.ftaEmail}</div>
                  </div>
                )}
              >
                FTA Name & Email
              </InfoColumn>
            ) : null}
            <InfoColumn
              width="150px"
              cell={(row) => (
                <div>
                  {renderOrgUsersLink(row, "enabled")}
                  {renderOrgUsersLink(row, "verified")}
                  {renderOrgUsersLink(row, "unverified")}
                </div>
              )}
            >
              Users
            </InfoColumn>
            {userRoleInOrg.isAgentAdmin ? (
              <InfoColumn
                width="150px"
                cell={(row) => (
                  <div>
                    {renderOrgTicketsLink(row, "open", currMonth)}
                    {renderOrgTicketsLink(row, "inProgress", currMonth)}
                    {renderOrgTicketsLink(row, "resolved", currMonth)}
                    {renderOrgTicketsLink(row, "closed", currMonth)}
                  </div>
                )}
              >
                Tickets
              </InfoColumn>
            ) : null}
            {userRoleInOrg.isSuperAdmin ? (
              <InfoColumn cell={renderOrgExempt} center>
                Exempt
              </InfoColumn>
            ) : null}
            {userRoleInOrg.isSuperAdmin ? (
              <InfoColumn cell={renderOrgDelete} center>
                Delete
              </InfoColumn>
            ) : null}
            <InfoColumn
              selector={({ orgItem }) => orgItem.createdAt}
              cell={({ orgItem }) => (
                <span>
                  {orgItem.createdAt
                    ? moment(orgItem.createdAt)
                        .utc()
                        .format("MMM DD, YYYY h:mm A")
                    : ""}
                </span>
              )}
              sortable
            >
              Date Created
            </InfoColumn>
            <InfoColumn
              selector={({ orgItem }) => orgItem.updatedAt}
              cell={({ orgItem }) => (
                <span>
                  {orgItem.updatedAt
                    ? moment(orgItem.updatedAt)
                        .utc()
                        .format("MMM DD, YYYY h:mm A")
                    : ""}
                </span>
              )}
              sortable
            >
              Date Updated
            </InfoColumn>
          </InfoTable>
        </div>
        <OrgDetailsModal
          orgItem={orgItem}
          modal={showOrgDetails}
          modaltoggle={this.handleShowOrgDetails}
        />
        <OrgUsersModal
          orgUsers={orgUsers}
          modal={!!orgUsers}
          modaltoggle={this.handleShowOrgUsers}
          title={orgUsersTitle}
        />
      </Card>
    );
  };
}

export default withAlert()(withRouter(ClientsList));
