import { FormGroup, Input, Label } from "reactstrap";
import { getServiceHosts } from "./helpers";

export default (props) => {
  const { registry, formContext, value, readonly } = props;
  const { widgets } = registry;
  const { TextWidget } = widgets;
  const { ticketDetails, formData = {} } = formContext;
  const { attributes: serviceAttributes = {} } = ticketDetails;
  const { cluster = {} } = formData;
  const defaultDnsName = `${cluster?.dnsName?.toLowerCase()}.${cluster?.nameserver}`;
  const { serviceHosts } = getServiceHosts(defaultDnsName, serviceAttributes);
  const defaultApp = serviceHosts.filter(({ appId }) => appId === "default")[0];
  const otherApps = serviceHosts.filter(({ appId }) => appId !== "default");
  return (
    <div className="extra-dns-widget">
      <FormGroup className="extra-dns-name-group">
        <Label>{defaultApp?.appTitle}</Label>
        <TextWidget {...props} disabled={readonly} />
      </FormGroup>
      {!value && otherApps.length > 0 ? (
        <>
          {otherApps.map((appItem) => (
            <div className="mt-3" key={appItem?.appId}>
              <Label>{appItem?.appTitle}</Label>
              <Input value="" disabled />
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};
