import { gql } from "@apollo/client";

export const TICKETHISTORY = gql`
  query ticketHistory($ticketId: Int!) {
    ticketHistoryById(ticketId: $ticketId) {
      action
      text
      objectId
      createdAt
      actionByUser {
        firstName
        lastName
        organizationRole {
          id
          role
        }
      }
    }
  }
`;
