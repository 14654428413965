import ErrorBoundary from "#Components/ErrorBoundary";
import React, { PureComponent } from "react";
import { Card } from "reactstrap";
import HistoryList from "./HistoryList";

class History extends PureComponent {
  state = {};

  render() {
    return (
      <Card className="content-wrapper p-2">
        <div>
          <ErrorBoundary error="bg-error">
            <HistoryList />
          </ErrorBoundary>
        </div>
      </Card>
    );
  }
}

export default History;
