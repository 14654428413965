import ErrorBoundary from "#Components/ErrorBoundary";
import { fetchTicketFields } from "#Graphql/query";
import withRouter from "#hoc/withRouter";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row, Spinner } from "reactstrap";
import CreateDeploymentForm from "./CreateDeploymentForm";
import DeploymentsList from "./DeploymentsList";

const CreateDeployment = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ticketFields, setTicketFields] = useState({});
  const [selectedCapacity, setSelectedCapacity] = useState("x-small");

  const { params } = props;
  const { serviceName } = params;

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const ticketFields = await fetchTicketFields();
      setTicketFields(ticketFields);
      setIsLoading(false);
    })();
  }, []);

  const handleSelectCapacity = (capacity) => {
    setSelectedCapacity(capacity);
  };

  const handleSelectDeployment = (solutionItem) => {
    props.history.push(`/deployment/create/${solutionItem.serviceName}`);
  };

  const handleCancelDeployment = () => {
    props.history.push("/deployment/create");
  };

  const renderTicketFooter = () => {
    return (
      <div className="add-ticket-footer text-center">
        <Row className="p-2">
          <Col>
            Need help creating a deployment? &mdash;{" "}
            <Link to={"/help"}>HELP</Link>
          </Col>
        </Row>
      </div>
    );
  };

  const { selfServiceTypes = [], getOrgTicketId } = ticketFields;

  const solutionItem = selfServiceTypes.find(
    (item) => item.serviceName === serviceName,
  );

  return (
    <Card className="content-wrapper">
      <ErrorBoundary error="bg-error">
        <div className="ticket-box">
          <Container className="add-ticket-col">
            {isLoading ? (
              <div className="loading-spinner">
                <Spinner />
              </div>
            ) : solutionItem ? (
              <CreateDeploymentForm
                orgTicketId={getOrgTicketId}
                solutionItem={{ ...solutionItem, selectedCapacity }}
                onCancel={handleCancelDeployment}
              />
            ) : (
              <DeploymentsList
                deploymentsList={selfServiceTypes}
                selectedCapacity={selectedCapacity}
                onSelectDeployment={handleSelectDeployment}
                onSelectCapacity={handleSelectCapacity}
              />
            )}
          </Container>

          {renderTicketFooter()}
        </div>
      </ErrorBoundary>
    </Card>
  );
};

export default withRouter(CreateDeployment);
