export const PasswordValidator = (requiredFields) => {
  return (values) => {
    const errors = {};
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = "Password is required";
      }
    });

    if (
      values.newPassword &&
      values.confirmPassword &&
      values.confirmPassword !== values.newPassword
    ) {
      errors.confirmPassword = "Passwords don't match";
    }
    return errors;
  };
};
