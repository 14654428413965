import { checkEmailExists } from "#Graphql/query";
import { emailRegex } from "#hoc/util";

export const AddOrgUserValidate = (values, { validatorOptions = {} }) => {
  const errors = {};
  const { existingUserData, alreadyAdded } = validatorOptions;
  const requiredFields = existingUserData
    ? ["email"]
    : ["firstName", "lastName", "email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  if (values.email) {
    if (!emailRegex.test(values.email)) {
      errors.email = "Invalid email address";
    } else if (alreadyAdded) {
      errors.email = "This email already exists";
    }
  }
  return errors;
};

export const AddAgentValidate = async (values) => {
  const errors = {};
  const requiredFields = ["firstName", "lastName", "email"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  if (values.email) {
    if (!emailRegex.test(values.email)) {
      errors.email = "Invalid email address";
    } else {
      const { checkEmail = {} } = await checkEmailExists(values["email"]);
      const { userData } = checkEmail;
      if (userData) {
        errors.email = "This email already exists";
      }
    }
  }
  return errors;
};
