export const validate = (values) => {
  const errors = {};
  const requiredFields = ["question", "description"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  return errors;
};
