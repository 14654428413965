import {
  CHECKCNAMEMAPPING,
  CHECKEMAIL,
  CLIENTTOKEN,
  CLOUVEAGENTSLIST,
  DNSRECORDEXISTS,
  FETCHHISTORY,
  GETCLIENTPAYMENTMETHOD,
  GETDEPLOYMENTSLIST,
  GETTICKETFIELDS,
  KUBECONFIGS,
  ORGANIZATIONS,
  ORGSNAPSHOT,
  ORGUSERSLIST,
  PRIORITIES,
  TICKETBYID,
  TICKETSBYSTATE,
} from "#Graphql/index";
import { client } from "#hoc/config";

export const fetchPriorities = async () => {
  try {
    const response = await client.query({
      query: PRIORITIES,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTicketFields = async () => {
  try {
    const response = await client.query({
      query: GETTICKETFIELDS,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchClientPaymentMethod = async () => {
  try {
    const response = await client.query({
      query: GETCLIENTPAYMENTMETHOD,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchSnapShot = async ({ orgId, ticketType, assignedTo }) => {
  try {
    const response = await client.query({
      query: ORGSNAPSHOT,
      variables: { orgId, ticketType, assignedTo },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTickets = async ({
  orgId,
  currMonth,
  createdBy,
  assignedTo,
  ticketState,
  ticketType,
}) => {
  try {
    const response = await client.query({
      query: TICKETSBYSTATE,
      variables: {
        orgId,
        currMonth,
        createdBy,
        assignedTo,
        ticketState,
        ticketType,
      },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchTicketById = async ({
  ticketId,
  orgTicketId,
  updatePlan,
  refreshAgentToken,
  refreshBuildStatus,
  refreshDeploymentStatus,
}) => {
  try {
    const response = await client.query({
      query: TICKETBYID,
      variables: {
        ticketId,
        orgTicketId,
        updatePlan,
        refreshAgentToken,
        refreshBuildStatus,
        refreshDeploymentStatus,
      },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchOrganizationUsers = async () => {
  try {
    const response = await client.query({
      query: ORGUSERSLIST,
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchClouveAgentsList = async (agentRole) => {
  try {
    const response = await client.query({
      query: CLOUVEAGENTSLIST,
      variables: { agentRole },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchOrganizations = async () => {
  try {
    const response = await client.query({
      query: ORGANIZATIONS,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchDeployments = async () => {
  try {
    const response = await client.query({
      query: GETDEPLOYMENTSLIST,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const checkDNSRecord = async (cluster) => {
  try {
    const response = await client.query({
      query: DNSRECORDEXISTS,
      variables: { cluster },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const checkCnameMapping = async (requestedDomains, cluster) => {
  try {
    const response = await client.query({
      query: CHECKCNAMEMAPPING,
      variables: { requestedDomains, cluster },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const checkEmailExists = async (email) => {
  try {
    const response = await client.query({
      query: CHECKEMAIL,
      variables: { email },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getClientToken = async () => {
  try {
    const response = await client.query({
      query: CLIENTTOKEN,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchKubeConfigs = async () => {
  try {
    const response = await client.query({
      query: KUBECONFIGS,
      variables: {},
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchHistory = async () => {
  try {
    const { user } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const response = await client.query({
      query: FETCHHISTORY,
      variables: {
        userId: user.userId,
        limit: 0,
      },
      fetchPolicy: "network-only",
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
