import InputForm, { SelectField, SubmitButton } from "#Components/InputForm";
import { submitAssignTicketToAgent } from "#Graphql/mutate";
import { fetchClouveAgentsList } from "#Graphql/query";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Button, Spinner } from "reactstrap";

class TicketAssignee extends PureComponent {
  state = {
    isLoading: false,
    isOpen: false,
    staList: [],
  };
  async componentDidMount() {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    try {
      if (userRoleInOrg.isAgentAdmin) {
        const { clouveAgentsList: staList } =
          await fetchClouveAgentsList("agent");
        this.setState({
          staList,
        });
      }
    } catch (err) {
      console.error(err);
      this.errorHandler(err);
    }
  }

  componentDidUpdate(prevProps) {
    const { editableFields } = this.props;
    if (editableFields.isEditableField !== "ticketAssignee") {
      this.setState({ isOpen: false });
    }
  }

  getAgentList = () => {
    const { ticketDetails } = this.props;
    const { staList } = this.state;
    if (staList) {
      const { fta } = ticketDetails;
      return [fta, ...staList];
    }
    return [];
  };

  renderAgentList = () => {
    const agentList = this.getAgentList();
    if (agentList && agentList.length) {
      return agentList.map((item, index) => (
        <option key={item.id} value={item.id}>
          {item.firstName + " " + item.lastName}{" "}
        </option>
      ));
    }
    return "";
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };
  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  handleEditFields = (key) => {
    const { editableFields } = this.props;
    editableFields.ticketEditFields(key);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  updateTicketAssignSubmit = async ({ formFields }) => {
    this.setState({ isLoading: true });
    const agentList = this.getAgentList();

    if (!agentList.length) {
      return;
    }

    this.setState({ isLoading: true });

    const { ticketDetails, onUpdate = () => {} } = this.props;
    try {
      const { assignTicketToAgent } = await submitAssignTicketToAgent({
        ticketId: ticketDetails.id,
        agentId: Number(formFields.currentAssigneeId),
      });
      if (assignTicketToAgent && assignTicketToAgent.code === 200) {
        this.showSuccessMessage(assignTicketToAgent.message);
        this.handleEditFields();
        onUpdate();
      } else {
        this.errorHandler(assignTicketToAgent);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.props;
    const { isOpen, isLoading } = this.state;

    if (!ticketDetails || isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    if (userRoleInOrg.isAgentAdmin) {
      return (
        <div className="editable-block">
          {isOpen ? (
            <div className="editable-field" style={{ width: "148px" }}>
              <InputForm
                initialValues={{
                  currentAssigneeId: ticketDetails?.currentAssignee?.id,
                }}
                onSubmit={this.updateTicketAssignSubmit}
              >
                <SelectField name="currentAssigneeId">
                  <option value="" disabled>
                    Please select
                  </option>
                  {this.renderAgentList()}
                </SelectField>
                <Button
                  color="link"
                  size="sm"
                  onClick={() => this.handleEditFields("ticketAssignee")}
                >
                  <i className="fa fa-times" />
                </Button>
                <SubmitButton color="link" size="sm">
                  <i className="fa fa-check" />
                </SubmitButton>
              </InputForm>
            </div>
          ) : (
            <span
              className="hover-edit"
              onClick={() => this.handleEditFields("ticketAssignee")}
              title="Click here to edit"
            >
              {`${ticketDetails.currentAssignee?.firstName || ""} ${ticketDetails.currentAssignee?.lastName || ""}`}
              <i className="fa fa-pencil" />
            </span>
          )}
        </div>
      );
    } else {
      return (
        <div className="not-editable-block">
          {`${ticketDetails.currentAssignee?.firstName || ""} ${ticketDetails.currentAssignee?.lastName || ""}`}
        </div>
      );
    }
  }
}

export default withAlert()(TicketAssignee);
