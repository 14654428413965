import { InfoColumn, InfoTable } from "#Components/InfoTable";
import { fetchHistory } from "#Graphql/query";
import { getUserIcon } from "#hoc/util.jsx";
import withRouter from "#hoc/withRouter";
import moment from "moment";
import React, { useEffect, useState } from "react";

const dateFormat = (value) => {
  return moment(value).format("MMM DD, YYYY h:mm A");
};
const conditionalRowStyles = [
  {
    when: (row) => ["ticket", "subscription"].includes(row.objectType),
    classNames: ["clickable"],
  },
];

const HistoryList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { fetchHistoryList } = await fetchHistory();
      setHistoryList(fetchHistoryList);
      setIsLoading(false);
    })();
  }, []);

  const onRowClicked = ({ objectType, objectId, data }) => {
    const { history } = props;
    if (["ticket", "subscription"].includes(objectType)) {
      history.push(`/${data.type.toLowerCase()}`, {
        ticketId: objectId,
      });
    }
  };

  if (historyList || historyList.length > 0) {
    console.log(historyList);
    return (
      <InfoTable
        data={historyList}
        onRowClicked={onRowClicked}
        conditionalRowStyles={conditionalRowStyles}
        pagination={historyList.length > 10}
        highlightOnHover
        progressPending={isLoading}
      >
        <InfoColumn
          selector={(row) => row.actionByUser}
          cell={(row) => (
            <i
              className={getUserIcon(row?.actionByUser?.organizationRole)}
              style={{ fontSize: "1.25rem" }}
            />
          )}
          sortable
          center
          width="20px"
        />
        <InfoColumn selector={(row) => row.text}>Action Performed</InfoColumn>
        <InfoColumn
          selector={(row) => row.createdAt}
          format={(row) => `${dateFormat(row.createdAt)}`}
          sortable
        >
          Date & Time
        </InfoColumn>
      </InfoTable>
    );
  }
};

export default withRouter(HistoryList);
