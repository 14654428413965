import { gql } from "@apollo/client";

export const TOGGLEADMINROLE = gql`
  mutation toggleAdminRole(
    $userId: Int!
    $admin: Boolean!
    $authorizedUser: Boolean!
  ) {
    toggleAdminRole(
      userId: $userId
      admin: $admin
      authorizedUser: $authorizedUser
    )
  }
`;
