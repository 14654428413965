import { FormGroup, Label } from "reactstrap";
export default (props) => {
  const { id = "", registry, formContext, readonly } = props;
  const { widgets } = registry;
  const { TextWidget } = widgets;
  const { formData = {} } = formContext;
  const { cluster = {} } = formData;
  const { extraDnsApps = [] } = cluster;
  const idx = id.split("_").reverse()[1];
  const appItem = extraDnsApps[idx];
  if (appItem?.appId === "default") {
    return null;
  }
  return (
    <FormGroup className="host-field">
      <Label>{appItem?.appTitle}</Label>
      <TextWidget {...props} disabled={readonly} />
    </FormGroup>
  );
};
