import AutoDeployment from "#Components/AutoDeployment";
import { fetchTicketById, fetchTicketFields } from "#Graphql/query";
import withRouter from "#hoc/withRouter";
import moment from "moment";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Link } from "react-router-dom";

import { Card, Col, Row, Spinner, Table } from "reactstrap";
import FileAttachment from "./components/FileAttachment";
import TicketAssignee from "./components/TicketAssignee";
import TicketChatHistory from "./components/TicketChatHistory";
import TicketDescription from "./components/TicketDescription";
import TicketEstimate from "./components/TicketEstimate";
import TicketPriorities from "./components/TicketPriorities";
import TicketStates from "./components/TicketStates";
import TicketTitle from "./components/TicketTitle";
import TicketUrgency from "./components/TicketUrgency";

class TicketDetails extends PureComponent {
  static ticketDataTimer = null;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ticketDetails: undefined,
      isTicketHistoryOpen: false,
      isEditableTicket: false,
      isEditableField: null,
      historyDetails: [],
      ticketFields: {},
    };

    this.commentsBlockRef = React.createRef();
  }

  async componentDidMount() {
    await this.loadTicketFields();
    await this.loadData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { ticketDetails } = this.state;
    if (ticketDetails?.comments !== prevState.ticketDetails?.comments) {
      if (this.commentsBlockRef.current) {
        this.commentsBlockRef.current.scrollTop =
          this.commentsBlockRef.current.scrollHeight;
      }
    }
    if (location?.pathname !== prevProps.location?.pathname) {
      await this.loadData();
    }
  }

  componentWillUnmount() {
    clearInterval(TicketDetails.ticketDataTimer);
  }

  loadTicketFields = async () => {
    this.setState({ isLoading: true });
    const ticketFields = await fetchTicketFields();
    this.setState({ ticketFields, isLoading: false });
  };

  loadData = async () => {
    const { userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { location, params } = this.props;
    const { state } = location;
    const { ticketId } = state || {};
    const { orgTicketId } = params;
    if (ticketId || orgTicketId) {
      this.setState({ ticketDetails: undefined, isLoading: true });
      const ticketDetails = await this.fetchUpdatedTicket({
        ticketId,
        orgTicketId,
        updatePlan: true,
        refreshAgentToken: userRoleInOrg.isAgent,
        refreshBuildStatus: true,
        refreshDeploymentStatus: true,
      });
      this.setState({ isLoading: false });
      if (!window.location.pathname.includes(ticketDetails.orgTicketId)) {
        window.history.replaceState(
          null,
          null,
          `/${ticketDetails.type.toLowerCase()}/${ticketDetails.orgTicketId}`,
        );
      }
    }
  };

  fetchUpdatedTicket = async (params = {}, onSuccess = () => {}) => {
    try {
      const { ticketById: ticketDetails = {} } = await fetchTicketById(params);
      this.setState({ ticketDetails, isLoading: false });
      return ticketDetails;
    } catch (err) {
      this.errorHandler(err);
    }
  };

  createdDate = () => {
    const { ticketDetails } = this.state;
    if (ticketDetails) {
      return moment(ticketDetails.createdAt).format("MMM DD, YYYY h:mm A");
    }
    return null;
  };

  updatedDate = () => {
    const { ticketDetails } = this.state;
    if (ticketDetails) {
      return moment(ticketDetails.updatedAt).format("MMM DD, YYYY h:mm A");
    }
    return null;
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = async (message) => {
    const { ticketDetails } = this.state;
    this.setState({ isLoading: false });
    this.props.alert.success(message);
    await this.fetchUpdatedTicket(ticketDetails);
  };

  ticketEditFields = (key) => {
    const { isEditableTicket } = this.state;
    this.setState({
      isEditableTicket: !isEditableTicket,
      isEditableField: key,
    });
  };

  onFieldUpdate = async () => {
    const { ticketDetails } = this.state;
    this.setState({ isLoading: true });
    await this.fetchUpdatedTicket(ticketDetails);
  };

  onRefreshTicketStatus = async () => {
    const { user, userRoleInOrg } = JSON.parse(
      sessionStorage.getItem("Clouve.object"),
    );
    const { ticketDetails } = this.state;
    await this.fetchUpdatedTicket({
      ...ticketDetails,
      refreshAgentToken: userRoleInOrg.isAgent,
      refreshBuildStatus: true,
      refreshDeploymentStatus: true,
    });
  };

  onRefreshBuildStatus = async () => {
    const { ticketDetails } = this.state;
    await this.fetchUpdatedTicket({
      ...ticketDetails,
      refreshBuildStatus: true,
    });
  };

  onRefreshDeploymentStatus = async () => {
    const { ticketDetails } = this.state;
    await this.fetchUpdatedTicket({
      ...ticketDetails,
      refreshDeploymentStatus: true,
    });
  };

  renderSupportTicket = () => {
    const { ticketFields } = this.state;
    const { urgencies, states } = ticketFields;

    const { isEditableTicket, ticketDetails, isLoading, isEditableField } =
      this.state;

    const editableFields = {
      isEditableField,
      isEditableTicket,
      ticketEditFields: this.ticketEditFields,
    };

    if (isLoading || !ticketDetails) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    const isDeployment = ticketDetails.type === "DEPLOYMENT";
    return (
      <div className="support-ticket">
        <Card className="mb-3">
          <Row className="ticket-dt-header">
            <Col sm="6">
              <Table size="sm">
                <tbody>
                  <tr>
                    <th>Status:</th>
                    <td>
                      <TicketStates
                        ticketDetails={ticketDetails}
                        editableFields={editableFields}
                        states={states}
                        onUpdate={this.onFieldUpdate}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Urgency:</th>
                    <td>
                      <TicketUrgency
                        ticketDetails={ticketDetails}
                        editableFields={editableFields}
                        urgencies={urgencies}
                        onUpdate={this.onFieldUpdate}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Priority:</th>
                    <td>
                      <TicketPriorities
                        ticketDetails={ticketDetails}
                        editableFields={editableFields}
                        onUpdate={this.onFieldUpdate}
                      />
                    </td>
                  </tr>
                  {!isDeployment ? (
                    <tr>
                      <th>App:</th>
                      <td>
                        {ticketDetails?.deployment?.ticket ? (
                          <Link
                            to={`/deployment/${ticketDetails?.deployment?.ticket?.orgTicketId}`}
                          >
                            {ticketDetails?.deployment?.ticket?.title}
                          </Link>
                        ) : null}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Col>
            <Col sm="6">
              <Table size="sm">
                <tbody>
                  <tr>
                    <th>Requester:</th>
                    <td>
                      {`${ticketDetails.createdByUser?.firstName || ""} ${ticketDetails.createdByUser?.lastName || ""}`}
                    </td>
                  </tr>
                  <tr>
                    <th>Assigned Expert:</th>
                    <td>
                      <TicketAssignee
                        ticketDetails={ticketDetails}
                        editableFields={editableFields}
                        onUpdate={this.onFieldUpdate}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>Created:</th>
                    <td>{this.createdDate()}</td>
                  </tr>
                  <tr>
                    <th>Updated:</th>
                    <td>{this.updatedDate()}</td>
                  </tr>
                  {ticketDetails.priority && (
                    <tr>
                      <th>Estimate:</th>
                      <td>
                        <div className="mb-1 estimate-wrapper">
                          <TicketEstimate
                            ticketDetails={ticketDetails}
                            editableFields={editableFields}
                            onUpdate={this.onFieldUpdate}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
        <TicketChatHistory
          ticketDetails={ticketDetails}
          onSuccess={this.showSuccessMessage}
          onFailure={this.errorHandler}
        />
        <FileAttachment
          ticketDetails={ticketDetails}
          onUpload={this.onFieldUpdate}
          onDelete={this.onFieldUpdate}
        />
      </div>
    );
  };

  render() {
    const { ticketFields } = this.state;
    const { selfServiceTypes } = ticketFields;

    const { isEditableTicket, ticketDetails, isEditableField } = this.state;

    const editableFields = {
      isEditableField,
      isEditableTicket,
      ticketEditFields: this.ticketEditFields,
    };

    if (!ticketDetails) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    const isDeployment = ticketDetails.type === "DEPLOYMENT";
    const classNames = ["ticket-details"];
    !isDeployment && classNames.push("support-ticket");
    return (
      <Card className="content-wrapper">
        <div className={classNames.join(" ")}>
          <Row>
            <Col className="title">
              <TicketTitle
                ticketDetails={ticketDetails}
                editableFields={editableFields}
                onUpdate={this.onFieldUpdate}
              />
            </Col>
          </Row>
          <Row className="pb-3">
            <Col sm="12">
              <TicketDescription
                ticketDetails={ticketDetails}
                editableFields={editableFields}
                onUpdate={this.onFieldUpdate}
              />
            </Col>
          </Row>
          {isDeployment ? (
            <Row>
              <Col sm="12">
                <AutoDeployment
                  ticketDetails={ticketDetails}
                  selfServiceTypes={selfServiceTypes}
                  supportTicket={this.renderSupportTicket()}
                  onRefreshTicketStatus={this.onRefreshTicketStatus}
                  onRefreshAgentToken={this.onRefreshAgentToken}
                  onRefreshBuildStatus={this.onRefreshBuildStatus}
                  onRefreshDeploymentStatus={this.onRefreshDeploymentStatus}
                />
              </Col>
            </Row>
          ) : (
            this.renderSupportTicket()
          )}
        </div>
      </Card>
    );
  }
}

export default withAlert()(withRouter(TicketDetails));
