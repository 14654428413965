import React, { PureComponent } from "react";

class ErrorBoundary extends PureComponent {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { error } = this.props;

    if (hasError) {
      return (
        <div className={error + " form-panel"}>
          <div className="error-icon">
            <i
              style={{ fontSize: "3rem", color: "red" }}
              className="fa fa-exclamation-circle"
            />
            <div className="m-3">Something went wrong.</div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
