import InputForm, { PasswordField, SubmitButton } from "#Components/InputForm";
import { submitResetPassword } from "#Graphql/mutate";
import { validateResetPassword } from "#pages/Profile/MyProfile/helpers";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

class ResetPassword extends PureComponent {
  state = {
    isLoading: false,
  };

  handleOnClose = () => {
    this.props.onClose();
  };

  errorHandler = (error) => {
    this.setState({ isLoading: false });
    this.props.alert.error(error.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
    this.props.onClose();
  };

  handleChangePassword = async ({ formFields }) => {
    const { newPassword } = formFields;
    try {
      this.setState({ isLoading: true });
      const { resetPassword } = await submitResetPassword({ newPassword });
      if (resetPassword && resetPassword.code === 200) {
        this.showSuccessMessage(resetPassword.message);
      } else {
        this.errorHandler(resetPassword);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  render() {
    const { isOpen, className } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return (
        <div className="loading-spinner">
          <Spinner type="grow" />
        </div>
      );
    }

    return (
      <div>
        <Modal isOpen={isOpen} backdrop={true} className={className}>
          {isLoading && (
            <div className="loading-spinner">
              <Spinner type="grow" />
            </div>
          )}
          <ModalHeader toggle={this.handleOnClose}>Reset Password</ModalHeader>
          <InputForm
            validator={validateResetPassword}
            onSubmit={this.handleChangePassword}
            validateOnBlur
          >
            <ModalBody className="ps-5 pe-5">
              <FormGroup className="hide-showPassword position-relative">
                <Label for="existingPassword">Existing Password</Label>
                <PasswordField id="existingPassword" name="password" />
              </FormGroup>

              <FormGroup id="password" className="position-relative">
                <Label for="newPassword">New Password</Label>
                <PasswordField
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter new password"
                  showStrengthBar={true}
                />
              </FormGroup>

              <FormGroup className="position-relative">
                <Label for="confirmPassword">Confirm Password</Label>
                <PasswordField
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm new password"
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={this.handleOnClose}>
                Cancel
              </Button>
              <SubmitButton color="primary">Save</SubmitButton>
            </ModalFooter>
          </InputForm>
        </Modal>
      </div>
    );
  }
}

export default withAlert()(ResetPassword);
